:root {
  --testimonial-height: 420px;
}

/** footer layout **/
footer#footer {
  flex-shrink: 0;
  background: _get($theme-colors, 'primary');

  background: linear-gradient(
    180deg,
    _get($theme-colors, 'primary40') 0,
    _get($theme-colors, 'primary40') var(--testimonial-height),
    _get($theme-colors, 'primary') var(--testimonial-height),
    _get($theme-colors, 'primary') 100%
  );

  @include media-breakpoint-up(xxl) {
    background: linear-gradient(
      90deg,
      _get($theme-colors, 'primary') 0,
      _get($theme-colors, 'primary') 50%,
      _get($theme-colors, 'primary40') 50%,
      _get($theme-colors, 'primary40') 100%
    );
  }

  min-height: 200px;
  color: _get($theme-colors, 'white');

  h3 {
    position: relative;
    color: _get($theme-colors, 'white');

    &:after {
      .root-430 & {
        display: none !important;
      }

      @include media-breakpoint-up(xl) {
        content: '';
      }

      position: absolute;
      background: url(#{$theme-resources}/Images/Dots.svg) no-repeat;
      width: 120px;
      height: 58px;
      transform: rotate(20deg);
      bottom: -65px;
      right: -40px;
      z-index: 1;
    }
  }

  h5 {
    @include getFont();
    margin: 0;
    color: _get($theme-colors, 'white');
  }

  .locations {
    &-nomap {
      .locations {
        @include make-col(12);

        & > ul {
          @extend .d-flex;

          & > li {
            flex: 1 0 100%;
            @include media-breakpoint-up(lg) {
              flex-basis: 50%;

              @include all-but(1) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    ul {
      @include removeListStyles;

      li {
        @include getFont(b);
        text-transform: uppercase;

        @include all-but(1) {
          @include media-breakpoint-up(xs) {
            margin-top: $grid-gutter-width * 2;
          }

          @include media-breakpoint-up(xl) {
            margin-top: $grid-gutter-width * 2;
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          margin-top: $grid-gutter-width / 2;
          padding-right: 0;

          li {
            @include getFont();
            text-transform: none;
            margin-top: 0 !important;
            flex: 0 1 50%;
            @include px-to-rem(line-height, 30px);
          }
        }
      }
    }
  }

  nav.footer {
    strong {
      line-height: 1.875rem;
    }

    @include media-breakpoint-up(md) {
      div:first-child {
        border-right: 3px dotted _get($theme-colors, 'primary');
      }
    }

    ul {
      @include removeListStyles;

      li {
        @include px-to-rem(line-height, 30px);

        a:hover {
          color: _get($theme-colors, 'primary');
        }
      }
    }
  }

  & + footer .row {
    min-height: $navigation-footer-height;
  }
}
