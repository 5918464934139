$sliderhomepage-height: 280px;

.Sliderhomepage__slider {
  overflow: hidden;
  height: $sliderhomepage-height;

  &image {
    height: 100%;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &.d-md-block {
    }
    &.d-lg-block {
    }
    &.d-xl-block {
    }
    &.d-xxl-block {
    }
  }

  &.slick-loading .slick-list {
    background: none;
  }

  .slick-list {
    .slick-track,
    .slick-slide > div {
      margin: 0;
    }

    .Sliderhomepage__slideritem {
      @extend .flex-row, .justify-content-center;
      height: $sliderhomepage-height;
    }
  }
}
