q {
  @extend .h4;

  footer & {
    color: _get($theme-colors, 'secondary');
    line-height: 1.4;
    display: block;
    margin-bottom: $grid-gutter-width * 1.5;
  }

  .box__infoLight & {
    color: _get($theme-colors, 'info');
    @include px-to-rem(line-height, 34px);
  }

  .quotations & {
    @include px-to-rem(font-size, 32px);
    @include px-to-rem(line-height, 39px);
  }
}
