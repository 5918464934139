/*+
	ONLY USE TOP AND LEFT for positioning!
	ONLY USE % units for positioning! otherwise
	the position isn't correct when the map is shown
	on a small viewport / get resized.
 */
$locations: (
  'at': (
    'hollabrunn': (
      'sidebar': (
        'top': 15.4%,
        'left': 81%,
      ),
      'footer': (
        'top': 380,
        'right': 60,
      ),
    ),
    'mistelbach': (
      'sidebar': (
        'top': 15.8%,
        'left': 86.7%,
      ),
      'footer': (
        'top': 400,
        'right': 10,
      ),
    ),
    'wien': (
      'sidebar': (
        'top': 27%,
        'left': 85.8%,
      ),
      'footer': (
        'top': 410,
        'right': 25,
      ),
    ),
    'wien-7': (
      'sidebar': (
        'top': 28.4%,
        'left': 85.4%,
      ),
      'footer': (
        'top': 410,
        'right': 25,
      ),
    ),
    'wien-22': (
      'sidebar': (
        'top': 28%,
        'left': 86.8%,
      ),
      'footer': (
        'top': 415,
        'right': 20,
      ),
    ),
    'moedling': (
      'sidebar': (
        'top': 31.5%,
        'left': 84%,
      ),
      'footer': (
        'top': 430,
        'right': 50,
      ),
    ),
    'baden': (
      'sidebar': (
        'top': 34.1%,
        'left': 83%,
      ),
      'footer': (
        'top': 433,
        'right': 33,
      ),
    ),
    'wr-neustadt': (
      'sidebar': (
        'top': 40%,
        'left': 82%,
      ),
      'footer': (
        'top': 441,
        'right': 34,
      ),
    ),
    'graz': (
      'sidebar': (
        'top': 66.1%,
        'left': 74.35%,
      ),
      'footer': (
        'top': 489,
        'right': 64,
      ),
    ),
    'leibnitz': (
      'sidebar': (
        'top': 72.2%,
        'left': 75.8%,
      ),
      'footer': (
        'top': 508,
        'right': 57,
      ),
    ),
    'klagenfurt': (
      'sidebar': (
        'top': 76.7%,
        'left': 61.1%,
      ),
      'footer': (
        'top': 520,
        'right': 108,
      ),
    ),
    'zwettl': (
      'sidebar': (
        'top': 13%,
        'left': 70.4%,
      ),
      'footer': (
        'top': 390,
        'right': 80,
      ),
    ),
    'krems': (
      'sidebar': (
        'top': 22.5%,
        'left': 78.7%,
      ),
      'footer': (
        'top': 400,
        'right': 56,
      ),
    ),
    'linz': (
      'sidebar': (
        'top': 24.5%,
        'left': 60.5%,
      ),
      'footer': (
        'top': 407,
        'right': 123,
      ),
    ),
    'wels': (
      'sidebar': (
        'top': 28.6%,
        'left': 57.8%,
      ),
      'footer': (
        'top': 420,
        'right': 140,
      ),
    ),
    'salzburg': (
      'sidebar': (
        'top': 54%,
        'right': 53%,
      ),
      'footer': (
        'top': 455,
        'right': 160,
      ),
    ),
    'innsbruck': (
      'sidebar': (
        'top': 56.5%,
        'left': 26.7%,
      ),
      'footer': (
        'top': 474,
        'right': 238,
      ),
    ),
    'st-poelten': (
      'sidebar': (
        'top': 27%,
        'left': 79%,
      ),
      'footer': (
        'top': 414,
        'right': 51,
      ),
    ),
    'attnang-puchheim': (
      'sidebar': (
        'top': 30.5%,
        'left': 56.5%,
      ),
      'footer': (
        'top': 418,
        'right': 140,
      ),
    ),
    'vorarlberg': (
      'sidebar': (
        'top': 60%,
        'left': 8%,
      ),
      'footer': (
        'top': 480,
        'right': 310,
      ),
    ),
    'dornbirn': (
      'sidebar': (
        'top': 52%,
        'left': 6%,
      ),
      'footer': (
        'top': 464,
        'right': 315,
      ),
    ),
    'schaerding': (
      'sidebar': (
        'top': 24.5%,
        'left': 50.5%,
      ),
      'footer': (
        'top': 410,
        'right': 150,
      ),
    ),
  ),
  'de': (
    'frankfurt': (
      'sidebar': (
        'top': 231px,
        'left': 83px,
      ),
      'footer': (
        'top': 295,
        'left': 105,
      ),
    ),
    'berlin': (
      'sidebar': (
        'top': 101px,
        'right': 42px,
      ),
      'footer': (
        'top': 135,
        'right': 165,
      ),
    ),
    'hamburg': (
      'sidebar': (
        'top': 50px,
        'left': 132px,
      ),
      'footer': (
        'top': 70,
        'left': 170,
      ),
    ),
    'duesseldorf': (
      'sidebar': (
        'top': 145px,
        'left': 35px,
      ),
      'footer': (
        'top': 205,
        'left': 45,
      ),
    ),
    'dresden': (
      'sidebar': (
        'top': 174px,
        'left': 243px,
      ),
      'footer': (
        'top': 230,
        'right': 155,
      ),
    ),
    'koeln': (
      'sidebar': (
        'top': 160px,
        'left': 55px,
      ),
      'footer': (
        'top': 220,
        'left': 65,
      ),
    ),
    'mannheim': (
      'sidebar': (
        'top': 273px,
        'left': 94px,
      ),
      'footer': (
        'top': 345,
        'left': 115,
      ),
    ),
    'regensburg': (
      'sidebar': (
        'top': 303px,
        'left': 210px,
      ),
      'footer': (
        'top': 370,
        'left': 260,
      ),
    ),
    'saarbruecken': (
      'sidebar': (
        'top': 265px,
        'left': 27px,
      ),
      'footer': (
        'top': 335,
        'left': 35,
      ),
    ),
    'stuttgart': (
      'sidebar': (
        'top': 311px,
        'left': 107px,
      ),
      'footer': (
        'top': 395,
        'left': 135,
      ),
    ),
    'nuernberg': (
      'sidebar': (
        'top': 255px,
        'right': 100px,
      ),
      'footer': (
        'top': 325,
        'right': 250,
      ),
    ),
    'muenchen': (
      'sidebar': (
        'top': 343px,
        'left': 190px,
      ),
      'footer': (
        'top': 425,
        'left': 240,
      ),
    ),
    'dortmund': (
      'sidebar': (
        'top': 145px,
        'left': 77px,
      ),
      'footer': (
        'top': 205,
        'left': 90,
      ),
    ),
    'hannover': (
      'sidebar': (
        'top': 110px,
        'left': 130px,
      ),
      'footer': (
        'top': 140,
        'left': 160,
      ),
    ),
    'augsburg': (
      'sidebar': (
        'top': 318px,
        'left': 154px,
      ),
      'footer': (
        'top': 398,
        'left': 194,
      ),
    ),
    'freiburg-im-breisgau': (
      'sidebar': (
        'top': 340px,
        'left': 60px,
      ),
      'footer': (
        'top': 448,
        'left': 64,
      ),
    ),
    'magdeburg': (
      'sidebar': (
        'top': 114px,
        'left': 180px,
      ),
      'footer': (
        'top': 148,
        'left': 228,
      ),
    ),
    'erfurt': (
      'sidebar': (
        'top': 185px,
        'left': 160px,
      ),
      'footer': (
        'top': 241,
        'left': 205,
      ),
    ),
    'bochum': (
      'sidebar': (
        'top': 150px,
        'left': 66px,
      ),
      'footer': (
        'top': 210,
        'left': 77,
      ),
    ),
    'duisburg': (
      'sidebar': (
        'top': 140px,
        'left': 40px,
      ),
      'footer': (
        'top': 200,
        'left': 50,
      ),
    ),
    'bremen': (
      'sidebar': (
        'top': 72px,
        'left': 93px,
      ),
      'footer': (
        'top': 100,
        'left': 120,
      ),
    ),
    'leipzig': (
      'sidebar': (
        'top': 160px,
        'left': 210px,
      ),
      'footer': (
        'top': 208,
        'left': 274,
      ),
    ),
    'muenster': (
      'sidebar': (
        'top': 113px,
        'left': 84px,
      ),
      'footer': (
        'top': 148,
        'left': 112,
      ),
    ),
    'kiel': (
      'sidebar': (
        'top': 28px,
        'left': 155px,
      ),
      'footer': (
        'top': 40,
        'left': 194,
      ),
    ),
  ),
);

.location-map {
  position: relative;

  height: 557px;
  width: 485px;

  &-top {
    position: relative;
  }

  ol,
  &-top ol {
    @include removeListStyles;
    margin: 0;

    li {
      position: inherit;
    }
  }

  .icon,
  &-top .icon {
    flex-direction: column;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    transition: color 0.2s ease-in-out;
    color: _get($theme-colors, 'info');

    footer & {
      color: _get($theme-colors, 'secondary');
    }

    width: 20px;

    &:before {
      margin: 0;
      font-size: 26px;
      color: inherit;
    }

    &[data-location]:hover,
    &.hover {
      color: _get($theme-colors, 'white');

      footer & {
        color: _get($theme-colors, 'info');
      }

      z-index: 2;

      &:after {
        content: attr(data-nice-location);
        @include getFont();
        @include border-radius();
        background: _get($theme-colors, 'dark');
        color: _get($theme-colors, 'white');
        padding: 5px;
        font-size: 1rem;
        margin-top: 5px;
        white-space: nowrap;
      }
    }

    // footer map
    @each $location in map-keys(_get($locations, 'at')) {
      &[data-location='#{$location}'] {
        @each $property, $value in _get($locations, 'at.#{$location}.footer') {
          #{$property}: #{$value}px;
        }
      }
    }

    @each $location in map-keys(_get($locations, 'de')) {
      &[data-location='#{$location}'] {
        @each $property, $value in _get($locations, 'de.#{$location}.footer') {
          #{$property}: #{$value}px;
        }
      }
    }
  }

  // top map in sidebar
  @each $location in map-keys(_get($locations, 'at')) {
    &-top .icon[data-location='#{$location}'] {
      @each $property, $value in _get($locations, 'at.#{$location}.sidebar') {
        #{$property}: #{$value};
      }
    }
  }

  @each $location in map-keys(_get($locations, 'de')) {
    &-top .icon[data-location='#{$location}'] {
      @each $property, $value in _get($locations, 'de.#{$location}.sidebar') {
        #{$property}: #{$value};
      }
    }
  }
}
