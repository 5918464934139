@font-face {
  font-family: 'icomoon';
  src: url('#{$icomoon-font-path}/icomoon.eot?m6nhu1');
  src:
    url('#{$icomoon-font-path}/icomoon.eot?m6nhu1#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?m6nhu1') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?m6nhu1') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?m6nhu1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
