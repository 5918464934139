label {
  margin: 0;

  &.checkbox {
    @extend .d-flex;
  }

  .form-group & {
    @extend .d-lg-block;
    @extend .mb-lg-2;

    em {
      font-size: 12px;
    }

    &.required {
      &:after {
        content: '*';
      }

      &.noAsterisk:after {
        content: '';
      }
    }
  }

  .form-group.row & {
    margin-bottom: 0 !important;
  }

  &.custom-control-label {
    margin-bottom: 0 !important;

    &:before,
    &:after {
      width: 20px;
      height: 20px;
      top: 2px;
      left: -$grid-gutter-width;
      box-shadow: none !important;
    }

    &:before {
      border: 2px solid _get($theme-colors, 'info');

      .custom-checkbox .custom-control-input ~ & {
        background: none;
      }

      .custom-checkbox .custom-control-input:checked ~ & {
        background: _get($theme-colors, 'info');
        border-color: _get($theme-colors, 'info');
      }
    }
  }
}
