#ui-datepicker-div {
  font-size: 14px;
  @include getFont();

  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    border-color: _get($theme-colors, 'info');
    background: _get($theme-colors, 'info');
    color: _get($theme-colors, 'white');
  }

  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active,
  a.ui-button:active,
  .ui-button:active,
  .ui-button.ui-state-active:hover {
    border-color: _get($theme-colors, 'info');
    background: _get($theme-colors, 'white');
    color: _get($theme-colors, 'info');
  }
}
