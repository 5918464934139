main#content {
  padding-top: $grid-gutter-width;

  flex: 1 0 auto;

  section > div {
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width * 3;
    }
  }
}
