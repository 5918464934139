// override the shake keyframes
@include keyframes(shake) {
  0%,
  100% {
    @include transform(translateX(0));
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    @include transform(translateX(-5px));
  }
  20%,
  40%,
  60%,
  80% {
    @include transform(translateX(5px));
  }
}

input[type='radio'],
input[type='checkbox'] {
  .checkbox.radio & {
    appearance: none;
    visibility: hidden;

    & + span {
      position: relative;
      @include icon(before, 'radio_button_unchecked');
      color: _get($theme-colors, 'info');
      z-index: 0;
      border-radius: 50%;
      cursor: pointer;

      &:before {
        z-index: 1;
      }
    }

    &:checked + span {
      @include icon(before, 'radio_button_checked');
    }
  }

  .checkbox.toggle & {
    appearance: none;
    visibility: hidden;

    & + span {
      position: relative;

      &:before {
        content: '';
        width: 60px;
        height: 30px;
        border: 3px solid _get($theme-colors, 'info');
        border-radius: 15px;
        display: flex;
        position: relative;
        top: auto;
        left: auto;
        transition: all 0.2s ease-in-out;
      }

      &:after {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 100%;
        left: 5px;
        top: 5px;
        position: absolute;
        transition: all 0.2s ease-in-out;
        z-index: 1;
        background: _get($theme-colors, 'info');
        text-align: center;
        color: #fff;
      }
    }

    &:checked + span {
      &:before {
        background: _get($theme-colors, 'info');
      }

      @include icon(after, 'radio_button_checked');

      &:after {
        transform: translate(30px, 0px);
        color: white;
        position: absolute;
        top: 1px;
        left: 2px;
        background: none;
        font-size: 27px;
      }
    }
  }

  &.reactive:not(:checked) + span {
    @include jello(999, 0.2s);
  }

  .checkbox.reactive &:not(:checked) + span {
    @include shake(1, $durationDefault, 1s);
  }
}
