.rating {
  @include removeListStyles;
  margin: $grid-gutter-width / 2 0 $grid-gutter-width * 2 0;

  footer & {
    margin: 0;
  }

  &__star {
    @include icon(before, star-full, 20px);

    &:before {
      color: _get($theme-colors, 'yellow');
    }

    display: inline-block;
    width: 20px;
    height: 20px;

    &-half {
      &:before {
        content: _get($icons, star-half);
      }
    }

    &-empty {
      &:before {
        content: _get($icons, star-empty);
      }
    }
  }
}
