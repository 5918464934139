ol {
  counter-reset: orderedList;
  list-style: none;

  main & {
    padding: 0;
    margin-bottom: $grid-gutter-width;
    list-style: none;

    li {
      counter-increment: orderedList;
      position: relative;
      padding-left: $grid-gutter-width;
      margin-bottom: $grid-gutter-width / 4;

      &:before {
        content: counter(orderedList, decimal-leading-zero) '. ';
        position: absolute;
        left: 0;
        top: 0px;
        color: _get($theme-colors, 'info');
        @include getFont(regular, teaser);
      }
    }
  }
}
