span[data-toggle='modal'] {
  cursor: pointer;
  position: relative;

  i {
    position: absolute;
    top: -10px;
    @include icon(after, 'info-invert', 21px);

    &:after {
      min-width: auto;
    }
    color: _get($theme-colors, 'info');
  }
}

.modal i.icon {
  display: none !important;
}
