.team-teaser {
  margin-bottom: 0;
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;

  li {
    padding-left: 0;
    margin-bottom: 0;

    .col-wrap {
      padding: 0 $grid-gutter-width / 2;
    }

    img {
      margin-bottom: $grid-gutter-width / 2;
    }

    span {
      display: flex;
    }

    span,
    a {
      line-height: 1.8;

      &.shy {
        line-height: 1.2;
        padding-left: $grid-gutter-width;

        span {
          line-height: inherit;
        }

        &:before {
          position: absolute;
          top: 5px;
          left: 0;
        }
      }
    }

    &:before {
      display: none;
    }
  }
}
