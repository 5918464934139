.btn {
  @include getFont(b);

  &.bounceIn {
    @include bounceIn($duration: 0.5s, $count: 1, $delay: 0, $function: ease-in-out, $fill: both);
  }

  &.bounceOut {
    @include shake();
  }

  &.btn-download {
    @extend .btn-link, .d-flex, .flex-row, .align-items-center;
    padding: 0;
    border: none;
    color: _get($theme-colors, 'info');
    @include getFont(b);

    &:hover {
      text-decoration: none;
      color: _get($theme-colors, 'info');
    }
    @include icon(before, download);

    &:before {
      margin-right: $grid-gutter-width / 2;
    }

    &:after {
      display: none !important;
    }
  }

  @each $button in map-keys($theme-colors) {
    &.btn-#{$button} {
      color: _get($theme-colors, 'white');

      &:hover {
        background: _get($theme-colors, $button);
        border-color: _get($theme-colors, $button);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.btn-link {
    padding: 0;
    border: none;
    color: _get($theme-colors, 'info');

    @each $button in map-keys($theme-colors) {
      &-#{$button} {
        color: _get($theme-colors, $button);
      }
    }

    &:after {
      content: '»';
      padding-left: 5px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &.btn-back {
    @extend .btn-link;
    @include icon(before);
    text-transform: uppercase;
    color: _get($theme-colors, 'light') !important;

    &:after {
      display: none !important;
    }

    &:before {
      transform: rotate(90deg);
      margin-right: $grid-gutter-width / 2;
    }
  }

  transition: none;

  .book-now & {
    height: auto;
  }
}
