@each $elem in map-keys($font-sizes) {
  #{$elem},
  .#{$elem} {
    @include getFont(b);
    color: _get($theme-colors, 'primary');

    .color-inherit & {
      color: inherit;
    }

    small {
      display: block;
      color: inherit;
      @include getFont();
    }

    @each $breakpoint, $size in _get($font-sizes, $elem) {
      @include media-breakpoint-up(#{$breakpoint}) {
        @include px-to-rem(font-size, $size);
        @include px-to-rem(margin-bottom, $size / 100 * 90);

        small {
          @include px-to-rem(font-size, $size / 100 * 85);
        }
      }
    }

    @each $breakpoint, $size in _get($line-heights, $elem) {
      @include media-breakpoint-up(#{$breakpoint}) {
        @include px-to-rem(line-height, $size);

        small {
          @include px-to-rem(line-height, $size);
        }
      }
    }
  }
}

h1 + h2 {
  @extend .h3;
  line-height: 1.3;
  opacity: 0.75;

  @include px-to-rem(margin-top, (__get($font-sizes, 'h1.xs') / 100 * 90) * -1);

  @include media-breakpoint-up(md) {
    @include px-to-rem(margin-top, (__get($font-sizes, 'h1.md') / 100 * 90) * -1);
  }
}

h5,
.h5 {
  color: _get($theme-colors, 'dark');
}
