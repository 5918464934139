.social-media {
  background: _get($theme-colors, 'primary60');
  padding: $grid-gutter-width;
  min-height: 200px;
  display: flex;
  align-items: center;
  margin-bottom: $grid-gutter-width * 2;

  h2 {
    position: relative;
    margin: 0;
    white-space: nowrap;
  }

  ul {
    @include removeListStyles;
    width: 100%;
    justify-content: space-evenly;

    li {
      @include px-to-rem(font-size, 20px);
      @include px-to-rem(line-height, 29px);
      color: _get($theme-colors, 'primary');

      @include icon(before, facebook, 24px);

      &.twitter {
        &:before {
          content: _get($icons, 'twitter');
        }
      }

      &:before {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        left: 0;
        width: 36px;
        height: 36px;
        background: _get($theme-colors, 'primary');
        color: _get($theme-colors, 'white');
        align-items: flex-end;
        justify-content: center;
      }

      &:after {
        content: '';
        position: absolute;
        left: -95px;
        top: -11px;
        background: url(#{$theme-resources}/Images/Paperplane.png) no-repeat;
        width: 95px;
        height: 58px;
      }

      padding-left: $grid-gutter-width * 2;
      height: 100%;
      max-width: 240px;
    }
  }
}
