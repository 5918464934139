b,
strong {
  @include getFont(b);

  i {
    @include getFont(bi);
  }

  &.jump-to-courses {
    color: __get($theme-colors, info);
    cursor: pointer;
  }
}
