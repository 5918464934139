.location-boxes {
  margin-bottom: 0;

  figure {
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
    }

    figcaption {
      position: absolute;
      top: $grid-gutter-width;
      left: -15px;
      width: auto;
      padding: 0;
      height: auto;
      transform: rotate(-5deg);

      span {
        @extend .bg-info;
        color: _get($theme-colors, 'white');
        display: inline-block;
        @include getFont(b);
        @include px-to-rem(font-size, 24px);
        padding: $grid-gutter-width / 2;

        &:first-child {
          padding-bottom: $grid-gutter-width / 4;
          @include px-to-rem(font-size, 18px);
        }

        &:last-child {
          padding-top: 0;
          margin-top: -5px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 0px solid transparent;
        border-bottom: 10px solid _get($theme-colors, 'infoDark');
        transform: rotate(-90deg);
      }
    }
  }

  .text {
    flex: 1;
    color: _get($theme-colors, 'info');

    p:last-child {
      margin: 0;
    }
  }

  .frame-type-Locationbox {
    @extend .d-flex, .flex-column;
  }
}
