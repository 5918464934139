body {
  content: "#{implode($grid-breakpoints, ',')}";

  &.develop {
    &:after {
      @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-only($breakpoint) {
          content: '#{$breakpoint}';
        }
      }

      position: fixed;
      line-height: 20px;
      font-size: 9px;
      color: #fff;
      bottom: 0;
      right: 0;
      padding: 3px;
      background: rgba(#000, 0.75);
      border: 1px solid rgba(#000, 0.9);
      text-transform: uppercase;
      z-index: 999;
    }
  }
}
