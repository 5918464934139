@font-face {
  font-family: 'Codec Warm';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../Webfonts/Codec-Warm-Regular.woff2') format('woff2'),
    url('../Webfonts/Codec-Warm-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Codec Warm Italic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../Webfonts/Codec-Warm-Italic.woff2') format('woff2'),
    url('../Webfonts/Codec-Warm-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Codec Warm Bold';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../Webfonts/Codec-Warm-Bold.woff2') format('woff2'),
    url('../Webfonts/Codec-Warm-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Tex Gyre Heros';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../Webfonts/texgyreheros-regular-webfont.woff2') format('woff2'),
    url('../Webfonts/texgyreheros-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Tex Gyre Heros Bold';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../Webfonts/texgyreheros-bold-webfont.woff2') format('woff2'),
    url('../Webfonts/texgyreheros-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Tex Gyre Heros Bold Italic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../Webfonts/texgyreheros-bolditalic-webfont.woff2') format('woff2'),
    url('../Webfonts/texgyreheros-bolditalic-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Tex Gyre Heros Italic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('../Webfonts/texgyreheros-italic-webfont.woff2') format('woff2'),
    url('../Webfonts/texgyreheros-italic-webfont.woff') format('woff');
}
