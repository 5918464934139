.persons {
  @include removeListStyles;

  li {
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;

    p:last-child {
      margin-bottom: 0;
    }

    li {
      margin: 0 0 $grid-gutter-width / 2 0;
      padding-left: $grid-gutter-width / 2;

      &:after {
        content: '•';
        position: absolute;
        left: 0;
        top: 4px;
        color: _get($theme-colors, 'info');
        font-family: initial;
        @include px-to-rem(font-size, 12px);
      }
    }
  }
}
