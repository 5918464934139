.claims {
  font-size: 10px;
  padding-top: $grid-gutter-width / 2;

  @include media-breakpoint-up(md) {
    font-size: 14px;
  }

  span {
    @include getFont(regular, teaser);
    @include px-to-rem(font-size, 28px, em);
    line-height: 1;
    display: block;

    & + span {
      padding-left: $grid-gutter-width * 2;
    }
  }
}
