$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-color: _get($theme-colors, 'dark');
$hamburger-active-layer-color: __get($theme-colors, 'dark');
$hamburger-hover-opacity: 1;

$hamburger-types: (elastic);

@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers';

.hamburger {
  position: absolute;
  right: $grid-gutter-width / 2;
  top: 20px;
  z-index: 2;
  height: auto;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &:focus {
    outline: none;
  }
}
