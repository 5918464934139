.reasons {
  @include border-radius;
  background: _get($theme-colors, 'primary60');
  padding: $grid-gutter-width;
  position: relative;

  &:before {
    content: '';
    background: url(#{$theme-resources}/Images/Dots.png) no-repeat;
    width: 37px;
    height: 91px;
    position: absolute;
    top: 50px;
    left: -18px;
  }

  ul {
    @include removeListStyles;

    li {
      padding-left: $grid-gutter-width;
      margin-bottom: $grid-gutter-width;

      @include last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        flex: 0 1 50%;
        padding-right: $grid-gutter-width;

        @include last(2) {
          margin-bottom: 0;
        }

        @include each(2) {
          padding-right: 0;
        }
      }

      @include px-to-rem(font-size, 20px);
      color: _get($theme-colors, 'primary');

      @include icon(before, checkbox-checked, 20px);

      &:before {
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
  }

  &__wide {
    background: _get($theme-colors, 'primary60');
    padding: $grid-gutter-width;
    min-height: 200px;
    display: flex;
    align-items: center;
    margin-bottom: $grid-gutter-width * 2;

    h2 {
      position: relative;
      margin: 0;
      align-self: flex-start;

      &:before {
        content: '';
        background: url(#{$theme-resources}/Images/Dots.png) no-repeat;
        width: 37px;
        height: 91px;
        position: absolute;
        top: 17px;
        left: 62px;
        transform: rotate(-75deg);
      }
    }

    ul {
      @include removeListStyles;
      width: 100%;
      justify-content: space-evenly;

      li {
        @include px-to-rem(font-size, 20px);
        @include px-to-rem(line-height, 29px);
        color: _get($theme-colors, 'primary');

        @include icon(before, checkbox-checked, 20px);

        &:before {
          position: absolute;
          top: 5px;
          left: 0;
        }

        padding-left: $grid-gutter-width;
        height: 100%;
        max-width: 240px;
      }
    }
  }

  .reason-icon {
    @each $icon in map-keys($icons) {
      @if str-index($icon, 'bs-') {
        &.reason-icon-#{$icon} {
          &:before {
            content: _get($icons, $icon);
            font-size: 20px;
          }
        }
      }
    }
  }
}
