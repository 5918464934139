.box {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: $grid-gutter-width 0;
  max-width: 100%;
  height: 100%;

  @each $color in map-keys($theme-colors) {
    &__#{$color} {
      background: _get($theme-colors, $color);
    }
  }

  &__100 {
    flex-basis: 100%;
    margin-bottom: $grid-gutter-width;

    .tmpl-Homepage & {
      margin-bottom: 0;
    }
  }

  &__50 {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;

    @include media-breakpoint-up(md) {
      flex-basis: 50%;
      max-width: 50%;
      padding-right: $grid-gutter-width / 2;
    }

    & + & {
      padding-right: 0;

      @include media-breakpoint-up(md) {
        padding-left: $grid-gutter-width / 2;
      }
    }
  }

  &__item {
    @extend .col-10, .align-self-center;
    flex-grow: 1;
    margin: 0 auto;

    footer .center & {
      text-align: center;
    }
  }

  &-wrap {
    @include make-row();

    .box__item {
      @extend .col-12, .align-self-start;
      margin-bottom: $grid-gutter-width;

      q,
      &-text {
        @extend .border-radius;
        @include getFont(i);
        @include px-to-rem(font-size, 16px);
        line-height: 1.5;
        border: 2px dotted _get($theme-colors, 'primary');
        display: block;
        padding: $grid-gutter-width / 2;
        position: relative;

        &:before {
          position: absolute;
          content: '';
          background: url(#{$theme-resources}/Images/Flag.png) no-repeat;
          width: 30px;
          height: 50px;
          background-size: auto 40px;
          top: -6px;
          right: 0px;
        }
      }

      .rating {
        margin: 0;
        float: left;
      }

      cite {
        margin: 0;
        float: right;
      }
    }
  }

  .frame-type-text {
    padding: $grid-gutter-width / 2;

    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width $grid-gutter-width * 4 $grid-gutter-width;
    }

    h2 {
      @include getFont();
      font-size: 1rem;
      line-height: normal;
    }

    p {
      @include px-to-rem(font-size, 24px);
      @include px-to-rem(line-height, 40px);

      &:last-child {
        margin-bottom: 0;
      }

      a {
        @include getFont(b);
        font-size: 1rem;
      }
    }
  }

  &.quotations {
    width: 100%;
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width * 2;
  }
}
