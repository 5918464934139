.opening-hours {
  p {
    b,
    strong {
      @include getFont();
      display: inline-block;
      width: $grid-gutter-width * 2;
    }

    margin-bottom: $grid-gutter-width / 2;

    &:last-child {
      margin: 0;
    }
  }
}
