// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.--bs-badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .--bs-badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

/**
  custom badges, conflicts with bootstrap badges, sorry
 */
.badge {
  @extend .d-flex;
  @include border-radius;
  background: _get($theme-colors, 'white');

  .invert & {
    background: none;
  }

  height: $grid-gutter-width * 2;
  margin-left: $grid-gutter-width / 2;

  &:first-child {
    margin-left: 0;
  }

  flex: 0 1 125px;

  &.big {
    flex: 0 0 auto;

    @include media-breakpoint-up(sm) {
      flex: 0 0 265px;
    }

    &.flex-100 {
      @include media-breakpoint-down(sm) {
        flex-basis: 100% !important;
      }
    }
  }

  &.small {
    flex: 0 0 auto;

    @include media-breakpoint-up(lg) {
      flex: 0 0 205px;
    }
  }

  &.tiny {
    flex: 0 1 auto;

    span > span {
      display: none;

      &:first-child {
        display: inline;
        font-size: 12px;
        position: absolute;
        top: 10px;
      }
    }

    &:before {
      padding-right: 0;
      font-size: 32px !important;
    }
  }

  padding-right: $grid-gutter-width / 2;
  padding-left: $grid-gutter-width / 2;

  &:before {
    padding-right: $grid-gutter-width / 2;
    font-size: 28px !important;
    margin-right: 0 !important;
    opacity: 0.4;
  }

  & > span {
    @extend .d-flex, .flex-column, .justify-content-center;

    span {
      @extend .d-block;
      @include getFont(b);
      @include px-to-rem(font-size, 20px);
      line-height: 1.1;

      & + span,
      & + time {
        @include getFont();
        @include px-to-rem(font-size, 12px);
        line-height: 1;
      }

      em {
        font-family: inherit !important;
        font-style: inherit;
        font-weight: inherit;
      }

      & > small {
        display: block;
        @include getFont();
        @include px-to-rem(font-size, 12px);
      }
    }

    & + i {
      position: absolute;
      right: -10px;
      top: -10px;
      @include icon(after, 'info-invert', 21px);

      &:after {
        min-width: auto;
      }

      color: _get($theme-colors, 'info');
    }
  }

  .invert & {
    height: auto;
    margin-left: 0;

    &.big {
      flex-basis: auto;

      @include media-breakpoint-up(lg) {
        flex-basis: 285px;
      }
    }

    & > span span {
      @include getFont();
      @include px-to-rem(font-size, 12px);
      line-height: 1;

      & + span,
      & + time {
        @include getFont(b);
        @include px-to-rem(font-size, 20px);
        line-height: 1.1;
      }
    }
  }

  &.revert {
    & > span span {
      @include px-to-rem(font-size, 20px, em);
      line-height: 1.1;

      & + span {
        @include px-to-rem(font-size, 12px, em);
        line-height: 1;
      }
    }
  }

  u {
    color: __get($theme-colors, 'info');
  }

  .aside-element & {
    background: none;
    padding: 0;
  }
}

.promotion {
  position: absolute;
  top: -16px;
  left: 20px;
  width: auto;
  padding: 4px 8px;
  color: $info;
  font-size: 12px !important;
  font-family: $base-font !important;
  border-radius: 3px;
  transform: rotate(5deg);
  text-decoration: line-through;

  .badge & {
    font-family: $base-font !important;
  }

  .modal-body & {
    left: calc(100% + 8px);
    top: -10px;
  }

  .cart & {
    top: -20px;
  }
}
