body {
  opacity: 1;
  transition: opacity 0.2s ease-in;
  color: _get($theme-colors, 'dark');

  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;

  -webkit-text-size-adjust: 100%;
  -webkit-font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;

  &[data-res='2'],
  &[data-res='3'] {
    opacity: 1;
  }
}
