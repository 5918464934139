@mixin px-to-rem($property, $px, $unit: rem) {
  $px: strip-unit($px);
  $rem: rem($px, $unit);
  #{$property}: #{$px}px;
  #{$property}: #{$rem};
}

@mixin getBackground($for: primary) {
  background-color: _get($theme-colors, $for);
  color: _get($theme-font-colors, $for);

  * {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

@mixin uppercase() {
  text-transform: uppercase;
  letter-spacing: 1;
}

@mixin removeListStyles() {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

@mixin checkboxHolder() {
}

@mixin selectHolder() {
  background: _get($colors, 'white');
  display: flex;
  position: relative;
  @include border-radius();
  width: 100%;

  .navigator & {
    height: $navigator-inner-height;
    margin-bottom: 1px;
    color: _get($theme-colors, info);

    @include media-breakpoint-up(md) {
      width: 50%;
      margin-bottom: 0;

      &:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin-right: 1px;
      }

      &:nth-child(2) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  @include icon(after);

  &:after {
    position: absolute;
    right: $grid-gutter-width / 2;
    color: _get($theme-colors, info);
  }
}

@mixin icon($position: after, $icon: angle-down, $size: 32px) {
  &:after,
  &:before {
    @extend .icomoon;
    align-self: center;
    pointer-events: none;
    font-size: $size;
    color: inherit;
    min-width: 20px;
    display: flex !important;
    position: relative;
    top: auto;
    left: auto;
  }

  @if $position == after {
    &:after {
      content: _get($icons, $icon);
    }
  }

  @if $position == before {
    &:before {
      content: _get($icons, $icon);
    }
  }
}

@mixin getFont($style: regular, $font: copy) {
  font-family: $base-font;
  font-weight: normal;
  font-style: normal;

  @if $font == copy {
    @if $style == regular {
      font-family: $base-font !important;
    }

    @if $style == b {
      font-family: $base-font-bold !important;
    }

    @if $style == i {
      font-family: $base-font-italic !important;
    }

    @if $style == bi {
      font-family: $base-font-bold-italic !important;
    }
  }

  @if $font == teaser {
    font-family: $teaser-font !important;

    @if $style == b {
      font-family: $teaser-font-bold !important;
    }

    @if $style == i {
      font-family: $teaser-font-bold !important;
    }
  }
}

@mixin half-grid-space($selector) {
  margin-left: 0;
  margin-right: 0;

  #{$selector} {
    padding-left: $grid-gutter-width / 4;
    padding-right: $grid-gutter-width / 4;
  }
}

/*
  returns the correct width / height ratio for each
  breakpoint for the given property. depending on the
  given column count. can be set in _variables.scss
  in $ratio-columns or overwritten just in place

  e.g.:
  $cols: (
    xxl: 5
  );

  @include ratioForBreakpoints(height, 77, 38, $cols);


  default is 3:2 on height property.
 */
@mixin ratioForBreakpoints($property: height, $x: 3, $y: 2, $columns: (), $gutter: 0) {
  $cols: _merge($ratio-columns, $columns);

  @each $breakpoint, $size in $grid-breakpoints {
    $col: _get($cols, $breakpoint);
    $width: round((($size / $grid-columns) * $col) - $grid-gutter-width);

    @if ($size > 0) {
      @include media-breakpoint-up($breakpoint) {
        #{$property}: ratio($x, $y, $width, $gutter);
      }
    } @else {
      #{$property}: ratio($x, $y, 320px, $gutter);
    }
  }
}

@mixin boxshadow() {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}
