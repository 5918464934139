.alert {
  button + & {
    margin-top: $grid-gutter-width / 2;
  }

  .errors {
    margin: 0;

    a {
      color: inherit;
      @include getFont();
      text-decoration: underline;
    }

    li:last-child {
      margin: 0;
    }
  }

  &.alert-danger ul {
    margin-bottom: 0;
  }
}
