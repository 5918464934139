aside {
  main section + & {
    padding-bottom: $grid-gutter-width;

    .frame-type-text {
      @extend .aside-element;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .aside-element {
    position: relative;
    padding: $grid-gutter-width / 2;

    &:first-child {
      &:before {
        position: absolute;
        content: '';
        background: url(#{$theme-resources}/Images/Flag.png) no-repeat;
        width: 30px;
        height: 50px;
        background-size: auto 50px;
        top: -4px;
        right: 10px;
      }
    }
  }
}
