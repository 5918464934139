.related-elements {
  @include make-row;
  @extend .d-flex;
  //margin: 0 (-$grid-gutter-width / 2) $grid-gutter-width * 2;

  &__item {
    @extend .col-12, .col-md-4;

    aside & {
      @extend .col-md-12;

      &.my-md-0 {
        margin-bottom: $grid-gutter-width !important;
      }
    }

    & > div {
      height: 100%;
    }
  }

  h4 {
    word-break: break-word;

    @supports (hyphens: auto) {
      word-break: normal;
      hyphens: auto;
    }
  }

  margin-bottom: $grid-gutter-width * 2;

  aside & {
    margin-bottom: 0;
  }
}
