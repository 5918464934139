@use 'sass:math';

@import 'container';
@import 'breakpoints';

$theme-resources: '../';
$icomoon-font-path: '../Webfonts/IcoMoon' !default;

$grid-gutter-width: 30px;

$white: rgb(255, 255, 255);
$primary: rgb(119, 159, 181);
$primaryBg: rgb(223, 234, 237);
$secondary: rgb(0, 73, 133);
$info: rgb(235, 105, 11);
$dark: rgb(67, 67, 67);
$light: rgb(183, 183, 183);
$text-muted: rgba($primary, 0.5);
$highlight: rgb(183, 173, 137);
$highlightBg: rgb(237, 234, 223);
$yellow: rgb(258, 201, 3);
$green: #28a745;
$lightBlue: #f0f5f8;
$red: $info;

$theme-font-colors: (
  'primary': $white,
  'white': $dark,
);

$theme-colors: (
  'white': $white,
  'light': $light,
  'lighter': lighten($light, 20%),
  'highlight': $highlight,
  'highlight40': rgba($highlight, 0.4),
  'highlight60': rgba($highlightBg, 0.6),
  'info': $info,
  'infoLight': rgba($info, 0.2),
  'infoDark': darken($info, 20%),
  'primary': $primary,
  'primary40': rgba($primary, 0.6),
  'primary60': rgba($primaryBg, 0.7),
  'primary61': rgba($primary, 0.8),
  'primaryBg': $primaryBg,
  'secondary': $secondary,
  'yellow': $yellow,
  'dark': $dark,
  'ok': $green,
  'lightBlue': $lightBlue,
);

$theme-colors-invert: (
  'white': $dark,
);

$badges: (
  'event': 'highlight',
  'course': 'primary',
  'special': 'infoLight',
);

$border-color: rgba($primary, 0.6);

$input-placeholder-color: rgba($primary, 0.6);

$font-size-base: 1rem;

$font-sizes: (
  h1: (
    xs: 30px,
    md: 40px,
  ),
  h2: (
    xs: 22px,
    md: 32px,
  ),
  h3: (
    xs: 18px,
    md: 28px,
  ),
  h4: (
    xs: 16px,
    md: 24px,
  ),
  h5: (
    xs: 14px,
    md: 16px,
  ),
);

$line-heights: (
  h1: (
    xs: 39px,
    md: 49px,
  ),
  h2: (
    xs: 39px,
  ),
  h3: (
    xs: 34px,
  ),
  h4: (
    xs: 29px,
  ),
  h5: (
    xs: 24px,
  ),
);

$ratio-columns: (
  xs: 12,
  sm: 12,
  md: 12,
  lg: 8,
  xl: 8,
  xxl: 8,
);

$spacers: (
  7: $grid-gutter-width * 4,
  12: 12px,
  15: $grid-gutter-width * 0.5,
  30: $grid-gutter-width,
  45: $grid-gutter-width * 1.5,
  60: $grid-gutter-width * 2,
  90: $grid-gutter-width * 3,
  fg: $grid-gutter-width,
);

$base-font: 'Tex Gyre Heros';
$base-font-italic: 'Tex Gyre Heros Italic';
$base-font-bold: 'Tex Gyre Heros Bold';
$base-font-bold-italic: 'Tex Gyre Heros Bold Italic';

$font-family-sans-serif: -apple-system, $base-font, Arial, sans-serif;
$teaser-font: 'Codec Warm', $base-font, Arial, sans-serif;
$teaser-font-italic: 'Codec Warm Italic', $base-font, Arial, sans-serif;
$teaser-font-bold: 'Codec Warm Bold', $base-font, Arial, sans-serif;

$navigator-outer-height: 70px;
$navigator-inner-height: 50px;
$navigation-top-height: 54px;
$navigation-footer-height: $navigator-outer-height;
$button-height: 50px;

$enable-rounded: true;
$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;
