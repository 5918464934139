.paperplane {
  position: relative;

  &.after {
    &:after {
      content: '';
      position: absolute;

      top: -39px;
      right: 0;

      @include media-breakpoint-up(lg) {
        right: -120px;
      }

      @include media-breakpoint-up(xl) {
        right: -156px;
        top: 0;
      }

      width: 154px;
      height: 58px;
      background: url(#{$theme-resources}/Images/Paperplane.png) no-repeat;
      background-size: 80% auto;
    }
  }
}
