.dotted-border {
  padding: $grid-gutter-width;
  @include border-radius;
  border: 2px dotted _get($theme-colors, 'primary');

  &-bottom {
    border-bottom: 2px dotted __get($theme-colors, 'primary40') !important;
  }

  &-top {
    border-top: 2px dotted __get($theme-colors, 'primary40') !important;
  }

  &-right {
    border-right: 2px dotted __get($theme-colors, 'primary40') !important;
  }
}
