body.module-cart {
  .cart-total {
    margin-bottom: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    //border-bottom: 2px dotted __get($theme-colors, 'primary40');
    @extend .dotted-border-bottom;

    h2 {
      color: _get($theme-colors, 'secondary');
      margin: 0;
      align-self: center;
    }

    .badge {
      font-size: 24px;
      margin-right: $grid-gutter-width * 3;

      &:before {
        font-size: 50px !important;
      }
    }
  }

  .cart-steps {
    @include removeListStyles;
    margin: $grid-gutter-width 0;

    li {
      counter-increment: item;
      display: flex;
      align-items: center;
      margin: 0 $grid-gutter-width / 4;
      opacity: 0.5;

      &:before {
        @include media-breakpoint-up(md) {
          content: counter(item);
        }
        display: flex;
        background: _get($theme-colors, 'primary40');
        @include getFont(b);
        @include px-to-rem(font-size, 24px);
        width: 56px;
        height: 56px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
      }

      &:after {
        @include media-breakpoint-up(md) {
          content: '';
        }

        display: flex;
        background: _get($theme-colors, 'primary40');
        width: 120px;
        height: 4px;
      }

      &:last-child:after {
        display: none;
      }

      span {
        @extend .h4;
        color: _get($theme-colors, 'dark');
        padding: 0 $grid-gutter-width / 2;
        margin: 0;
      }
    }

    &.active {
      &_1 li:nth-child(1) {
        opacity: 1;
      }

      &_2 li {
        &:nth-child(1),
        &:nth-child(2) {
          opacity: 1;
        }
      }
      &_3 li {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          opacity: 1;
        }
      }
    }
  }

  .download-box {
    border-bottom-width: 2px;
    border-bottom-color: _get($theme-colors, 'primary40');

    h4 {
      color: _get($theme-colors, 'dark');
    }

    li {
      border-top-width: 2px;
      border-top-color: _get($theme-colors, 'primary40');
    }

    .btn:after {
      display: none !important;
    }
  }
}
