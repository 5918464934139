.book-now {
  padding: ($grid-gutter-width / 2);
  background: _get($theme-colors, 'primaryBg');
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0;
  border-radius: 0;
  border: none;
  box-shadow: 0 0 10px _get($theme-colors, 'dark');

  a {
    color: _get($theme-colors, 'white');
  }
}
