.courses {
  [data-toggle] {
    cursor: pointer;

    /** this may come back later, see issue #9015 **/
    //&.badge.icon-bubbles {
    //  &.initially-hidden, &[aria-expanded="true"] {
    //    opacity: 0;
    //    transition: opacity .2s ease-in-out;
    //  }
    //
    //  &.collapsed {
    //    opacity: 1;
    //  }
    //}
  }

  &_group {
    @include border-radius;
    transition: all 0.5s ease-in-out;
    margin-bottom: 0;

    &:hover {
      box-shadow: 0 0 30px rgba(_get($theme-colors, 'dark'), 0.3);

      .cart & {
        box-shadow: none;
      }
    }

    h3 {
      color: _get($theme-colors, 'info');

      &[aria-expanded] {
        i {
          &:before {
            @include getFont();
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
            content: 'Hier Termine ausklappen';
            position: absolute;
            left: 50%;
            top: -50px;
            font-size: 12px;
            line-height: 1;
            width: 80px;
            margin-left: -40px;
            text-align: center;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 5px;
            color: #fff;
            padding: 5px 10px;
            z-index: 1;
          }
        }
      }

      &[aria-expanded='false'] i:before {
        opacity: 1;
      }
    }

    &-item {
      position: relative;

      > div > article {
        position: static;

        p:last-child {
          margin: 0;
        }
      }

      h3[data-toggle] {
        @include icon();
        padding-right: $grid-gutter-width / 2;

        @include media-breakpoint-up(lg) {
          &:hover {
            text-decoration: underline;
          }
        }

        &:after {
          position: absolute;
          right: $grid-gutter-width / 2;
          top: 20px;
          color: _get($theme-colors, 'info');
          transform: rotate(0);
          transition: transform 0.2s ease-in-out;

          @include media-breakpoint-up(lg) {
            font-size: 40px;
          }
        }

        i {
          position: absolute;
          width: 20px;
          height: 20px;
          right: $grid-gutter-width / 2;
          top: 25px;
        }

        &[aria-expanded='true']:after {
          transform: rotate(-180deg);
        }
      }

      .event & {
        background: _get($theme-colors, 'highlight60');
      }

      background: _get($theme-colors, 'primary60');

      @include border-radius;
      padding: $grid-gutter-width ($grid-gutter-width * 2) $grid-gutter-width $grid-gutter-width;

      .cart & {
        padding-right: $grid-gutter-width;
      }

      margin-bottom: $grid-gutter-width;

      &:before {
        display: none;
      }

      &.active {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 5px solid _get($theme-colors, 'primary40');

          @include media-breakpoint-up(md) {
            border-width: 10px;
          }

          border-radius: $border-radius;
          pointer-events: none;
        }
      }

      &-schedule {
        @extend .col-12;
        margin: ($grid-gutter-width / 2) 0 0 0;

        & > li {
          padding: 0;
          margin: 0 0 ($grid-gutter-width / 2) 0;
          background: _get($theme-colors, 'white');
          @include border-radius;
          color: _get($theme-colors, 'info');

          &[data-reactive]:not(.not-yet-bookable) {
            cursor: pointer;
          }

          &:last-child {
            margin: 0;
          }

          & > div {
            padding: $grid-gutter-width / 2;

            &.active {
              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 2px solid _get($theme-colors, 'primary40');

                @include media-breakpoint-up(md) {
                  border-width: 5px;
                }

                border-radius: $border-radius;
                pointer-events: none;
              }
            }
          }

          h4 {
            color: inherit;
            margin: 0;
            display: flex;
            align-items: flex-start;

            span {
              color: _get($theme-colors, 'white') !important;
              font-size: 12px;
              margin-left: $grid-gutter-width / 2;
            }
          }

          &:before {
            display: none;
          }

          &.overbooked {
            * {
              color: _get($theme-colors, 'lighter');
            }

            .checkbox {
              visibility: hidden;
            }
          }

          [data-toggle] {
            h4 {
              @include icon('before');
              &:before {
                align-self: flex-start;
                margin-right: $grid-gutter-width / 2;
                transition: all 0.2s ease-in-out;
              }
            }

            &[aria-expanded='true'] {
              h4:before {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }

      // event beratungsgespräch specials
      // no badges, no schedules, simple text
      &.formtype_1 {
      }
    }
  }

  .is-online {
    body.develop & {
      box-shadow: inset 0px -4px 0px __get($theme-colors, 'info');
    }
  }
}
