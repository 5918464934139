.download-box {
  @include removeListStyles;
  border-bottom: 1px dotted _get($theme-colors, 'primary');

  li {
    border-top: 1px dotted _get($theme-colors, 'primary');
    height: auto;
    padding: $grid-gutter-width 0;

    @include media-breakpoint-up(md) {
      height: $grid-gutter-width * 2;
    }

    a {
      @include getFont();
      color: _get($theme-colors, 'dark');
      height: 100%;

      &:hover {
        text-decoration: none;
      }

      span {
        align-self: center;
      }
    }
  }
}
