p {
  @include getFont();
  @include px-to-rem(font-size, 16px);
  @include px-to-rem(line-height, 24px);
  @include px-to-rem(margin-bottom, $grid-gutter-width);

  .aside-element &:last-child {
    margin-bottom: 0;
  }
}
