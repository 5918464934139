.custom-control-input {
  .was-validated & {
    &:valid {
      & ~ .custom-control-label {
        color: _get($theme-colors, 'dark');

        &:before {
          background: none;
        }
      }
    }

    &:valid:checked {
      & ~ .custom-control-label {
        &:before {
          background: _get($theme-colors, 'info');
        }
      }
    }
  }

  &.is-valid {
    & ~ .custom-control-label {
      color: _get($theme-colors, 'dark');
    }
  }
}

.was-validated {
  .custom-select,
  .form-control,
  .custom-control-input {
    &:focus {
      box-shadow: none !important;
    }

    &:valid {
      background-image: none;

      & ~ .custom-control-label,
      &:checked ~ .custom-control-label {
        &:before {
          border-color: _get($theme-colors, 'info') !important;
        }
      }
    }

    &:invalid,
    &.invalid {
      border: none;
      box-shadow: inset 0 0 0 2px _get($theme-colors, 'info') !important;
      background-image: none;
      padding: 12px;

      & ~ .custom-control-label {
        color: _get($theme-colors, 'info');
      }
    }
  }
}

.form-control {
  &.error {
    box-shadow: inset 0 0 0 2px __get($theme-colors, info);

    & ~ .error {
      @extend .invalid-feedback;
      display: block;
    }
  }
}
