.uppercase {
  text-transform: uppercase;
  letter-spacing: 1;
}

.text-muted {
  @include getFont();
}

.sticky {
  position: sticky;
  top: 0;
}

[href] {
  cursor: pointer;
  word-break: break-word;

  aside & {
    word-break: break-word;
  }
}

.removeListStyles {
  @include removeListStyles;
}

.flex-basis-33 {
  flex-basis: calc(100% / 3) !important;
}

.border-radius {
  @include border-radius();
}

.cols-33 {
  & > .frame-type-text {
    @extend .col-12;
  }

  & > .frame-type-theme_textimage {
    @extend .col-12, .col-md-6, .col-lg-4, .d-flex, .flex-column;

    &:last-child {
      @extend .col-md-12, .col-lg-4;
    }

    body.layout-1 & {
      @extend .col-12, .col-md-6, .col-lg-6;

      &:last-child {
        @extend .col-md-6, .col-lg-6;
      }

      img {
        width: 100%;
      }
    }

    picture {
      order: 1;
    }

    header {
      order: 2;
    }

    p {
      order: 3;
    }
  }
}

// forces a col-xl to be at least 50% with
.col-xl.w-50 {
  @extend .w-50;
  flex-basis: 50%;
}

@each $color in map-keys($theme-colors) {
  .background-color-#{$color} {
    background-color: map-get($theme-colors, $color) !important;
  }

  .color-#{$color},
  .#{$color} p {
    color: map-get($theme-colors, $color) !important;
  }

  .border.border-#{$color} {
    border-color: map-get($theme-colors, $color) !important;
  }
}
