#toTop {
  display: none;

  @include media-breakpoint-up(sm) {
    display: flex;
  }

  position: fixed;
  bottom: $grid-gutter-width / 2;
  right: $grid-gutter-width / 2;
  width: 50px;
  height: 50px;
  background: __get($theme-colors, 'primary60');
  justify-content: center;
  transform: translateX(100px);
  opacity: 0;
  transition: all 0.2s ease-in-out;

  i {
    transform: rotate(180deg);
  }

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }

  &:hover {
    text-decoration: none;
  }
}
