.category-overview {
  @include removeListStyles;

  li {
    background: _get($theme-colors, 'primary60');
    margin-bottom: $grid-gutter-width / 2;
    @include border-radius($border-radius);

    a {
      @extend .d-flex;
      @include icon();
      @include border-radius($border-radius);

      @include media-breakpoint-up(md) {
        min-height: 75px;
      }

      &:after {
        position: absolute;
        right: $grid-gutter-width / 2;
        transform: rotate(-90deg);
        display: none !important;
      }

      picture {
        --list-image-width: 100%;
        --list-image-ar: 4/3;

        @include media-breakpoint-up(md) {
          --list-image-width: 220px;
        }

        flex: 1 0 var(--list-image-width);
        max-width: var(--list-image-width);
        overflow: hidden;
        aspect-ratio: var(--list-image-ar);
        @include border-radius($border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        @include media-breakpoint-up(md) {
          border-top-right-radius: 0;
          border-bottom-left-radius: $border-radius;
        }

        img {
          aspect-ratio: var(--list-image-ar);
        }
      }

      .title {
        position: relative;
        padding-top: $grid-gutter-width / 4;
        padding-bottom: $grid-gutter-width / 4;
        flex-grow: 1;

        @include media-breakpoint-up(md) {
          padding-top: 0;
          padding-bottom: 0;
        }

        @include icon();

        &:after {
          position: absolute;
          right: 10px;
          top: 5px;

          @include media-breakpoint-up(md) {
            top: -5px;
          }

          transform: rotate(-90deg);
        }

        span {
          margin: 0 $grid-gutter-width * 1.5 0 $grid-gutter-width / 2;
          display: block;
          word-break: break-word;
          @include getFont();
          font-size: 14px;
          line-height: 1.5;

          color: _get($theme-colors, 'dark');

          &:first-child {
            @include getFont(b);
            font-size: 20px;
            color: _get($theme-colors, 'info');
          }
        }
      }
    }
  }

  .frame-layout-0 & {
    .center-view {
      @include media-breakpoint-up(md) {
        align-items: center;
      }
    }

    li a {
      @extend .flex-column, .flex-md-row;
    }

    picture {
    }
  }

  .frame-layout-100 & {
    @include make-row();
    margin-top: -$grid-gutter-width / 2;
    margin-bottom: -$grid-gutter-width / 2;

    li {
      @extend .col-12, .col-md-6;
      display: flex;
      background: none;
      margin: 0;
      padding-top: $grid-gutter-width / 2;
      padding-bottom: $grid-gutter-width / 2;

      a {
        flex: 1 auto;
        flex-direction: column;
        background: _get($theme-colors, 'primary60');

        picture {
          --list-image-width: 100%;
          flex: 0 auto;

          aspect-ratio: 16/9;

          img {
            aspect-ratio: 16/9;
            width: 100%;
          }
        }
      }
    }
  }
}
