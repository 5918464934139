ul {
  main & {
    padding: 0;
    margin-bottom: $grid-gutter-width;
    list-style: none;

    ul {
      margin: $grid-gutter-width / 4 0;
    }

    li {
      position: relative;
      padding-left: $grid-gutter-width / 2;
      margin-bottom: $grid-gutter-width / 4;

      &:before {
        content: '•';
        position: absolute;
        left: 0;
        top: 4px;
        color: _get($theme-colors, 'info');
        font-family: initial;
        @include px-to-rem(font-size, 12px);
      }

      li {
        &:before {
          content: '○';
          top: 3px;
        }
      }
    }

    &.document-list {
      list-style: none;

      li {
        display: inline;
        padding: 0;

        &:before {
          display: none;
        }

        &:after {
          content: ', ';
        }

        &:last-child:after {
          content: '';
        }

        a {
          @include px-to-rem(font-size, 14px);
        }
      }
    }
  }

  .frame-type-text & {
    li p {
      margin: 0;
    }
  }

  .aside-element.frame-type-menu_subpages & {
    list-style: disc;
    color: __get($theme-colors, 'info');
    padding-left: $grid-gutter-width / 1.5;
  }

  &.list-unstyled {
    margin-left: $grid-gutter-width / -4;
    margin-right: $grid-gutter-width / -4;

    li {
      padding-left: $grid-gutter-width / 4;
      padding-right: $grid-gutter-width / 4;
      margin-bottom: $grid-gutter-width / 2;
      &:before {
        display: none;
      }
    }
  }
}
