@charset "utf-8";

// custom variables and mixins
@import 'Abstracts/variables';
@import 'Abstracts/functions';
@import 'Abstracts/mixins';
@import 'Abstracts/IcoMoon_icons';
@import 'Abstracts/helper';

// import vendor styles
@import "Vendors/_01_sassdash.scss";
@import "Vendors/_animate.scss";
@import "Vendors/_bootstrap.scss";
@import "Vendors/_family.scss";
@import "Vendors/_hamburgers.scss";
@import "Vendors/_icomoon.scss";
@import "Vendors/_slickcarousel.scss";
@import "Vendors/_supi.scss";

// Base Styles
@import "Layout/_base.scss";
@import "Layout/_main.scss";
@import "Base/_debug.scss";
@import "Base/_fonts.scss";

// Atoms
@import "Atoms/_a.scss";
@import "Atoms/_address.scss";
@import "Atoms/_aside.scss";
@import "Atoms/_b.scss";
@import "Atoms/_badge.scss";
@import "Atoms/_blockquote.scss";
@import "Atoms/_body.scss";
@import "Atoms/_button.scss";
@import "Atoms/_cite.scss";
@import "Atoms/_dotted-border.scss";
@import "Atoms/_fieldset.scss";
@import "Atoms/_figcaption.scss";
@import "Atoms/_figure.scss";
@import "Atoms/_headlines.scss";
@import "Atoms/_hr.scss";
@import "Atoms/_html.scss";
@import "Atoms/_i.scss";
@import "Atoms/_icon.scss";
@import "Atoms/_iframe.scss";
@import "Atoms/_img.scss";
@import "Atoms/_input.scss";
@import "Atoms/_label.scss";
@import "Atoms/_logo.scss";
@import "Atoms/_ol.scss";
@import "Atoms/_opening-hours.scss";
@import "Atoms/_p.scss";
@import "Atoms/_paperplane.scss";
@import "Atoms/_picture.scss";
@import "Atoms/_q.scss";
@import "Atoms/_radio.scss";
@import "Atoms/_select.scss";
@import "Atoms/_small.scss";
@import "Atoms/_textarea.scss";
@import "Atoms/_time.scss";
@import "Atoms/_ul.scss";

// Molecules
@import "Molecules/_add-to-cart.scss";
@import "Molecules/_alert.scss";
@import "Molecules/_aside-element.scss";
@import "Molecules/_avatar.scss";
@import "Molecules/_badges.scss";
@import "Molecules/_book-now.scss";
@import "Molecules/_box.scss";
@import "Molecules/_button-bar.scss";
@import "Molecules/_buttons.scss";
@import "Molecules/_calendar.scss";
@import "Molecules/_cart.scss";
@import "Molecules/_category-overview.scss";
@import "Molecules/_ce-gallery.scss";
@import "Molecules/_certs.scss";
@import "Molecules/_claims.scss";
@import "Molecules/_course-type-overview.scss";
@import "Molecules/_courses.scss";
@import "Molecules/_custom-control.scss";
@import "Molecules/_datepicker.scss";
@import "Molecules/_download-box.scss";
@import "Molecules/_faq.scss";
@import "Molecules/_footnotes.scss";
@import "Molecules/_form-validation.scss";
@import "Molecules/_form.scss";
@import "Molecules/_honeypot.scss";
@import "Molecules/_infobox.scss";
@import "Molecules/_jobs.scss";
@import "Molecules/_location-boxes.scss";
@import "Molecules/_location-list.scss";
@import "Molecules/_location-map.scss";
@import "Molecules/_locationteaser.scss";
@import "Molecules/_map.scss";
@import "Molecules/_navigation.scss";
@import "Molecules/_navigator.scss";
@import "Molecules/_overlay.scss";
@import "Molecules/_page-header.scss";
@import "Molecules/_persons.scss";
@import "Molecules/_quickselect.scss";
@import "Molecules/_rating.scss";
@import "Molecules/_reasons.scss";
@import "Molecules/_related-elements.scss";
@import "Molecules/_selectHolder.scss";
@import "Molecules/_slider.scss";
@import "Molecules/_sliderhomepage.scss";
@import "Molecules/_social-media.scss";
@import "Molecules/_team-teaser.scss";
@import "Molecules/_textimage.scss";
@import "Molecules/_to-top.scss";
@import "Molecules/_youtube.scss";

// Organisms
@import "Organism/_boxes.scss";
@import "Organism/_footer.scss";
@import "Organism/_header.scss";

// Components
@import "Components/_modal.scss";
@import "Components/_slider.scss";

// Utilities
@import "Utilities/_grid.scss";

// workaround, due to scss compiling errors
// on deployment machine
.flex-basis-100 {
  @include media-breakpoint-between(xs, sm) {
    flex-basis: 100% !important;
  }
}

// special method to remove background colors
// on given breakpoints and downwards
@each $bp in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($bp) {
    .background-color-#{$bp}-none {
      background-color: transparent !important;
    }
  }
}
