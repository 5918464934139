.youtube {
  position: relative;

  .embed-responsive {
    .embed-responsive-item {
      display: flex;
    }

    img {
      align-self: center;
    }

    &:before {
      min-height: 380px;
    }
  }
}
