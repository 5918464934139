cite {
  @include getFont();
  display: block;

  .box__infoLight & {
    color: _get($theme-colors, 'info');
  }

  q + &,
  q + ul + & {
    margin-top: $grid-gutter-width * 2;
    color: _get($theme-colors, 'primary');

    footer & {
      color: _get($theme-colors, 'secondary');
    }
  }
}
