.faq {
  @include removeListStyles;
  border-bottom: 2px dotted __get($theme-colors, 'primary40');

  li {
    border-top: 2px dotted __get($theme-colors, 'primary40');
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    @include icon(before, 'add');
    cursor: pointer;

    &:before {
      position: absolute;
      top: 28px;
      left: 0;
      background: __get($theme-colors, 'primary40');
      color: __get($theme-colors, 'white');
    }

    & > div ul li {
      padding-top: 15px;
      padding-left: 20px;
      border-top: 0px;
      padding-bottom: 0px;
      cursor: initial;

      &:before {
        content: '\2022';
        color: __get($theme-colors, 'dark');
        font-size: $font-size-base;
        background: none;
        top: 18px;
      }
    }

    &[aria-expanded='true']:before {
      content: _get($icons, subtract);
    }

    h4 {
      margin: 0;
      color: _get($theme-colors, 'dark');
      padding-left: $grid-gutter-width * 1.5;
    }

    div {
      padding-left: $grid-gutter-width * 1.5;
      padding-top: $grid-gutter-width / 2;

      p {
        @include last-child {
          margin: 0;
        }
      }
    }
  }
}
