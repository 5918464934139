.course-type-overview {
  @include removeListStyles;

  &__group {
    margin-bottom: $grid-gutter-width !important;
    border: 1px solid _get($theme-colors, 'primary60');
    @include border-radius();

    h4 {
      padding: $grid-gutter-width / 2;
      margin: 0;
      background: _get($theme-colors, 'primary60');
    }

    &-items {
      padding: $grid-gutter-width / 2;
      margin: 0;

      &-item {
        margin-bottom: $grid-gutter-width / 2 !important;

        &:last-child {
          margin-bottom: 0 !important;
        }

        small {
          padding-left: 27.5px;
        }
      }
    }
  }
}
