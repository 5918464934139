.logo {
  background: url(../Images/Svg/mera-logos_vektor.svg) no-repeat;
  background-size: 800px;

  text-indent: -9999px;
  height: 0px;
  padding-top: 70px;
  width: 220px;
  margin-top: -4px;

  @include media-breakpoint-up(sm) {
    width: 280px;
    margin-top: -4px;
  }

  body[data-tree*='Studentenkurse'] & {
    background-position: -236px -226px;
    min-width: 270px;
  }

  body[data-tree*='Matura'] & {
    background-position: -276px -119px;
  }

  body[data-tree*='IFL'] & {
    background-position: -236px -12px;
  }

  body[data-tree*='Bildungsforum'] & {
    background-position: -276px -333px;
  }

  body[data-tree*='Privatakademie'] & {
    background-position: -276px -440px;
  }
}
