.footnotes {
  @include removeListStyles;
  margin-top: $grid-gutter-width / 2;

  li {
    @include px-to-rem(font-size, 12px);
    padding: 0;
    counter-increment: item;

    &:before {
      position: relative;
      top: -6px;
      content: counter(item);
      display: inline;
      @include getFont();
      font-size: 10px;
      color: inherit;
      padding-right: 5px;
    }

    &.asterisk {
      &:before {
        content: '*';
      }
    }
  }
}
