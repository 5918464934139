.add-to-cart {
  &.fixed-mobile-button {
    button {
      position: fixed;
      right: -50px;
      z-index: 999;
      top: 50%;
      transform: rotate(90deg);
    }
  }

  &:not(.fixed-mobile-button) {
    position: relative;

    @include media-breakpoint-up(md) {
      &:hover {
        &:after,
        &:before {
          display: block;
        }
      }
    }

    &:after {
      content: attr(data-title2);
    }

    &.disabled:after {
      content: attr(data-title);
    }

    &:after {
      position: absolute;
      font-size: 11px;
      color: _get($theme-colors, 'white');
      background: _get($theme-colors, 'light');
      @include border-radius();
      padding: 5px 10px;
      right: 20px;
      top: -30px;
      text-align: left;
      max-width: 250px;

      @include media-breakpoint-up(md) {
        right: 170px;
        top: 11px;
        text-align: right;
        font-size: 12px;
        max-width: none;
      }

      display: none;
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background: _get($theme-colors, 'light');
      transform: rotate(45deg);
      right: 95px;
      top: 6px;
      z-index: 1;
      position: absolute;

      @include media-breakpoint-up(md) {
        right: 166px;
        top: 20px;
      }

      display: none;
    }
  }
}
