input {
  &.form-control {
    &[type='date'],
    &[type='text'],
    &[type='email'],
    &[type='number'],
    &[type='tel'] {
      border: none;
      height: calc(2.875rem + 2px);
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button,
    &[type='date']::-webkit-inner-spin-button,
    &[type='date']::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type='date']::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    &[type='date'] {
      & + .icon {
        position: absolute;
        right: 8px;
        top: 47px;
        color: _get($theme-colors, 'info');
        pointer-events: none;
      }
    }

    //&:valid {
    //  .was-validated & {
    //    background-image: none;
    //  }
    //}
  }
}
