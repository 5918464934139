:root {
  --sticky-height: -54px;
  --header-position: relative;

  @include media-breakpoint-up(md) {
    --header-position-fix: -webkit-sticky;
    --header-position: sticky;
  }
}

#header {
  position: var(--header-position);

  @supports (position: -webkit-sticky) {
    position: var(--header-position-fix);
  }

  top: calc(var(--sticky-height) - 1px);

  z-index: 2;

  .icon:before {
    margin-right: 0;
  }

  @include media-breakpoint-up(xxl) {
    .navigation-top__location:not(.absolute) .icon.icon-phone span {
      display: inline-block;
      padding-right: $grid-gutter-width / 2;
    }
  }
}
