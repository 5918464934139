@import '../../../node_modules/slicknav/scss/slicknav';

.navigation {
  &-top {
    @include media-breakpoint-up(lg) {
      @include getBackground();
    }

    height: 5px;

    @include media-breakpoint-up(md) {
      height: $navigation-top-height;
    }

    &.sm-sticky {
      // this is only the sticky mobile navigation.
      @include media-breakpoint-down(sm) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: #fff;
        z-index: 1;
        box-shadow: 0 0 10px #000;
      }
    }

    .slicknav {
      &_menu {
        @extend .d-lg-none;
        background: none;
        position: absolute;
        top: 0;
        height: 50px;
        padding: 0;
        right: $grid-gutter-width / 2;
        width: calc(100% - (#{$grid-gutter-width}));

        .meta {
          position: absolute;
          top: 5px;
          left: 0;
          width: 100%;
          height: 50px;
          padding: 0;

          ul {
            margin: 0 -10px;
            padding: 0;
            display: flex;
            align-items: center;
            height: 45px;
            list-style: none;

            a {
              color: _get($theme-colors, 'dark');
              padding: 10px;
              margin: 0;
              height: 100%;
            }
          }
        }
      }

      &_btn {
        display: none;
      }

      &_nav {
        position: absolute;
        top: 45px;
        left: -15px;
        width: calc(100% + (#{$grid-gutter-width}));
        z-index: 1;
        background: _get($theme-colors, 'primary');
        border-top: 5px solid __get($theme-colors, 'white');

        a {
          &:hover,
          &:focus {
            background: none;
            border-radius: 0;
            outline: none;
            color: inherit;
          }
        }

        & > li:not(.meta) {
          padding: 0;
          border-bottom: 1px solid rgba(#fff, 0.3);

          &.has-submenu {
            position: relative;
            @include icon();

            &:after {
              transition: all 0.2s ease-in-out;
              position: absolute;
              right: 15px;
              top: 12px;
              font-size: 18px;
            }

            &.slicknav_open:after {
              transform: rotate(-180deg);
              right: 22px;
            }

            padding-top: 10px;
          }

          &:last-child {
            border-bottom: 0;
          }

          a {
            @include getFont('b');
            margin: 0;
            padding: 10px $grid-gutter-width / 2;
          }

          .slicknav_row {
            margin: 0;
            padding: 0 0 10px 0;

            .slicknav_arrow {
              display: none;
            }

            &:hover,
            &:focus {
              background: none;
              border-radius: 0;
              outline: none;
            }
          }

          ul {
            margin: 0;
            padding-left: $grid-gutter-width / 2;
            padding-bottom: $grid-gutter-width / 2;

            a {
              @include getFont();
              padding: 5px 0;
            }
          }

          &.highlight {
            a {
              background: __get($theme-colors, info);
            }
          }
        }
      }
    }

    & > .container {
      @extend .d-none, .d-lg-block;
    }

    &__main,
    &__location {
      ul {
        @extend .d-flex;
        @include removeListStyles;
        margin-left: -$grid-gutter-width / 2;
        margin-right: -$grid-gutter-width / 2;

        // navigation hotfix only for LG until
        // the navigation concept gets a review
        // #11413
        @include media-breakpoint-only(lg) {
          margin-left: -10px;
          margin-right: -10px;
        }

        ul {
          display: none !important;
        }

        li {
          @extend .d-flex;

          &.highlight {
            a {
              background: __get($theme-colors, info);
            }
          }

          a {
            @include getFont(b);
            padding: $grid-gutter-width / 2;

            // navigation hotfix only for LG until
            // the navigation concept gets a review
            // #11413
            @include media-breakpoint-only(lg) {
              @include getFont();
              padding-left: 10px;
              padding-right: 10px;
            }

            display: inline-block;
            white-space: nowrap;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &__location {
      &.absolute {
        position: fixed;
        z-index: 1;
        top: var(--location-nav-width, 343px);
        right: 0;
        background: _get($theme-colors, 'white');
        transform: rotate(90deg);
        transform-origin: top right;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0 0 10px rgba(#000, 0.3);

        &.single {
          right: -10px;
          padding-right: 10px;
        }

        a {
          color: _get($theme-colors, 'primary');

          &:before {
            color: inherit;
            transform: rotate(-90deg);
          }

          &:after {
            transform: rotate(-90deg);
            right: 20px;
          }
        }
      }

      ul {
        @include half-grid-space(li a);

        li a {
          @include getFont();
        }
      }
    }
  }

  &-bottom {
    background-color: _get($theme-colors, 'secondary');
    min-height: $navigator-outer-height;
    margin-top: 45px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  &-anchor {
    @include getBackground('white');
    height: $navigation-top-height;
    box-shadow: 0 3px 5px rgba($dark, 0.2);

    ul {
      @include removeListStyles;
      margin-left: -$grid-gutter-width / 2;
      margin-right: -$grid-gutter-width / 2;

      &.shrink {
        max-width: 80%;
        overflow-y: scroll;

        @include icon();

        &:after {
          position: absolute;
          left: 78%;
          transform: rotate(-90deg);
          font-size: 18px;
          min-width: 0;
        }
      }

      li {
        @extend .d-flex;

        a {
          padding: $grid-gutter-width / 2;
          white-space: nowrap;

          &.highlight {
            span {
              background: _get($theme-colors, 'info');
              padding: 10px;
              border-radius: 5px;
              color: #fff;
              box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      &.location {
        @include half-grid-space(li a);
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &.visible {
          opacity: 1;
        }

        a,
        a:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-breadcrumb {
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-width;
    }

    &__list {
      @include removeListStyles;

      & > li {
        @include px-to-rem(font-size, 14px);
        line-height: 1.2;
        position: relative;
        padding-right: $grid-gutter-width;
        display: inline-block;

        &.submenu {
          text-decoration: underline;

          &:hover ul,
          &.hover ul {
            display: block;
          }
        }

        &.active {
          > a {
            text-decoration: underline;
          }
        }

        a + ul {
          margin-left: -(120px + ($grid-gutter-width / 2));
        }

        ul {
          position: absolute;
          display: none;
          left: 50%;
          margin-left: -120px;
          width: 240px;
          list-style: none;
          padding: 15px 0 0 0;
          border-radius: 5px;
          z-index: 1;
          transition: all 0.5s ease-in-out;

          li {
            background: lighten($primaryBg, 0.4);
            padding: 5px 15px;

            &:first-child {
              padding-top: 10px;
              border-top-left-radius: $border-radius;
              border-top-right-radius: $border-radius;
            }

            &:last-child {
              padding-bottom: 10px;
              border-bottom-left-radius: $border-radius;
              border-bottom-right-radius: $border-radius;
            }
          }

          &:before {
            content: '';
            width: 14px;
            height: 14px;
            background: lighten($primaryBg, 0.4);
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -7px;
            top: 7px;
            transform: rotate(45deg);
          }

          li:after {
            display: none;
          }
        }

        &:after {
          position: absolute;
          right: 13px;
          top: 0;
          content: '»';
        }

        &:last-child {
          padding-right: 0;

          &:after {
            display: none;
          }
        }

        a {
          color: _get($theme-colors, 'info');
          font-size: inherit;
        }
      }
    }
  }

  &-footer {
    &__meta {
      @extend .d-sm-flex;
      @include removeListStyles;

      li {
        a {
          color: _get($theme-colors, 'info');
          padding: 0 $grid-gutter-width / 2;
        }
      }
    }
  }
}
