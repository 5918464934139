[data-slider]:not(.Sliderhomepage__slider) {
  overflow: hidden;

  .slick-slide picture > img {
    display: unset;
    width: 100%;
    height: auto;
  }

  &.slick-loading .slick-list {
    background: none;
  }

  .slick-list {
    .slick-track,
    .slick-slide > div {
      footer & {
        display: flex;
      }

      margin: 0;
    }

    figure {
      .box & {
        @extend .flex-row, .justify-content-center;
      }

      div {
        padding-top: $grid-gutter-width / 4;
        min-height: $grid-gutter-width;
      }
    }
  }

  &.slick-dotted {
    margin-bottom: 0;
    padding-bottom: $grid-gutter-width * 1.5;

    &.left {
      .slick-dots {
        @extend .justify-content-start;
        left: 10px;
      }
    }

    &.center {
      .slick-dots {
        @extend .justify-content-center;
        left: 0px;
      }
    }

    .slick-dots {
      @include removeListStyles;
      position: absolute;
      bottom: 0;

      .frame-type-Slider & {
        width: auto;
        right: 0;
        background: _get($theme-colors, 'white');
      }

      @extend .d-flex, .justify-content-end;
      right: 10px;

      li {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: rgba(_get($theme-colors, info), 0.2);

        .color-secondary & {
          background: rgba(_get($theme-colors, 'secondary'), 0.2);
        }

        transition: background-color 2s ease-in-out;
        margin: 0 5px;

        &.slick-active {
          background: _get($theme-colors, 'info');

          .color-secondary & {
            background: _get($theme-colors, 'secondary');
          }
        }

        button {
          display: none;
        }
      }
    }
  }

  .slick-arrow {
    z-index: 1;
    width: $grid-gutter-width;
    height: $grid-gutter-width;

    &:before {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: $grid-gutter-width;
      height: $grid-gutter-width;
      border-top-left-radius: 3px;
      border-top: 5px solid __get($theme-colors, info);
      border-left: 5px solid __get($theme-colors, info);
      box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.2);
    }

    &.slick-next {
      right: $grid-gutter-width;
      transform: rotate(135deg);
    }

    &.slick-prev {
      left: $grid-gutter-width;
      transform: rotate(-45deg);
    }
  }
}
