.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70px;
  height: 70px;

  &.xl {
    width: 140px;
    height: 140px;

    &:after {
      position: absolute !important;
    }
  }

  border-radius: 50%;
  background: _get($theme-colors, 'lighter');
  margin-bottom: $grid-gutter-width / 2;
  position: relative;

  &.avatar-male {
    //@include icon(before, profile-male);
  }

  &.avatar-female {
    //@include icon(before, profile-female);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: _get($theme-colors, 'dark');
    filter: blur(10px);
    z-index: -1;
  }
}
