$supi-colors: (
  primary: $dark,
  success: $info,
);

$supi-border: false;

@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

:root {
  --supi-color-dismiss: white;
  --supi-color-dismiss-background: #{$info};
  --supi-color-choose-invert: white;
  --supi-color-choose: #{$info};
  --supi-button-wrap-bgcolor: #{$primaryBg};
}

.tx-supi {
  h3,
  .h3,
  h2,
  .h2 {
    color: $dark;
  }

  &__link-button[aria-expanded]:before {
    top: 21px;
  }

  &__block {
    &-item {
      &__text {
        padding: 0;
      }
    }
  }

  &.theme-default {
    .tx-supi__link-button {
      height: auto;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        position: relative;
        top: auto;
        left: auto;
        margin-right: $grid-gutter-width * 0.5;
      }
    }
  }

  &__block-item__table {
    padding-bottom: $grid-gutter-width;
  }

  &__youtube-text {
    top: auto;
    background: rgba(map-get($theme-colors, 'white'), 0.9);
  }

  &__youtube {
    &-iframe {
      iframe {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
      }
    }
  }

  &-overlay {
    align-items: center;
  }
}
