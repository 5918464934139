button,
input[type='submit'] {
  height: $button-height;

  .aside-element & {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.btn {
    @include getFont(b);
    height: $button-height;

    &.btn-info {
      &[disabled],
      &[diabled]:hover {
        background: _get($theme-colors, 'primary') !important;
        border-color: _get($theme-colors, 'primary') !important;
      }
    }

    // set the hover color of each button to
    // the same color as it normaly has
    @each $button in map-keys($theme-colors) {
      &.btn-#{$button} {
        &:hover {
          background: _get($theme-colors, $button);
          border-color: _get($theme-colors, $button);
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    &.btn-back {
      @extend .btn-link;
      @include icon(before);
      text-transform: uppercase;
      color: _get($theme-colors, 'light');

      &:after {
        display: none !important;
      }

      &:before {
        transform: rotate(90deg);
        margin-right: $grid-gutter-width / 2;
      }
    }

    transition: none;
  }

  [data-truncate] & {
    border: none;
    background: none;
    padding: 0 0 0 $grid-gutter-width * 0.25;
    font-weight: bold;
    color: __get($theme-colors, 'info');
    height: auto;
  }
}
