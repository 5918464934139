select {
  outline: none;

  .select-holder & {
    width: 100%;
    appearance: none;
    border: none;
    border-radius: 0;
    padding: 0 $grid-gutter-width / 2;

    &#category {
      padding: 0 40px 0 $grid-gutter-width / 2;
    }

    @include media-breakpoint-up(xxl) {
      &#category {
        padding: 0 $grid-gutter-width / 2;
      }
    }

    cursor: pointer;
    background: none;
    @include px-to-rem(font-size, 18px);

    .navigator & {
      color: _get($theme-colors, 'info');
    }
  }

  &.custom-select {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='28' height='38' viewBox='0 0 18 28'%3E%3Ctitle%3Eangle-down%3C/title%3E%3Cpath fill='%23EB690B' d='M16.797 11.5c0 0.125-0.063 0.266-0.156 0.359l-7.281 7.281c-0.094 0.094-0.234 0.156-0.359 0.156s-0.266-0.063-0.359-0.156l-7.281-7.281c-0.094-0.094-0.156-0.234-0.156-0.359s0.063-0.266 0.156-0.359l0.781-0.781c0.094-0.094 0.219-0.156 0.359-0.156 0.125 0 0.266 0.063 0.359 0.156l6.141 6.141 6.141-6.141c0.094-0.094 0.234-0.156 0.359-0.156s0.266 0.063 0.359 0.156l0.781 0.781c0.094 0.094 0.156 0.234 0.156 0.359z'%3E%3C/path%3E%3C/svg%3E%0A") !important;
    background-size: 20px !important;
    border: none;
    height: calc(2.875rem + 2px);
    min-height: 48px;
  }
}
