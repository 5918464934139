.aside-element {
  @include border-radius;
  margin-bottom: $grid-gutter-width / 4;
  background: _get($theme-colors, 'primary60');

  &.invert {
    background: none;
    border: 1px solid _get($theme-colors, 'primary60');
  }

  .badges {
    margin-bottom: $grid-gutter-width;
  }

  h4 {
    padding-right: $grid-gutter-width;
    hyphens: auto;
    color: _get($theme-colors, 'dark');
    margin-bottom: $grid-gutter-width / 2;

    position: relative;

    &:before {
      position: absolute;
      left: -28px;
      top: 22px;
      content: '';
      background: url(../Images/swirl.png) no-repeat left top;
      width: 40px;
      height: 70px;
      filter: brightness(0);
      transform: rotate(-6deg);
    }
  }

  & > p {
    &.do-not-hide {
      display: none;
    }

    margin-bottom: $grid-gutter-width / 2;

    &:last-of-type {
      margin-bottom: $grid-gutter-width / 4;
    }
  }

  ul {
    @include removeListStyles;
    margin: $grid-gutter-width / 2 0;
  }
}
