.frame-type-Certs {
  @extend .dotted-border-top;

  .certs {
    ul {
      list-style: none;
      margin: $grid-gutter-width 0;

      li {
        flex-basis: 100%;

        @include media-breakpoint-up(md) {
          flex-basis: 50%;

          @include even() {
            border-left: 2px dotted __get($theme-colors, 'primary40');
          }
        }

        @include media-breakpoint-up(xl) {
          flex-basis: auto;
          border-left: 2px dotted __get($theme-colors, 'primary40');

          &:first-child {
            border: none;
          }
        }

        text-align: center;
        margin: $grid-gutter-width / 2 0;
        padding: 0 $grid-gutter-width * 2;

        &:before {
          display: none;
        }
      }
    }
  }
}
