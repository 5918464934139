/**
  the styles for the navigator wrap and
  possibly the select fields inside.
 */
.navigator {
  display: flex;
  @extend .flex-wrap, .flex-md-nowrap;
  align-items: center;
  height: auto;
  padding-bottom: $grid-gutter-width / 2;

  @include media-breakpoint-up(md) {
    height: $navigator-outer-height;
    padding-bottom: 0;
  }
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 70%;
    max-width: 600px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 780px;
  }

  .select-holder {
    &.location {
      @include icon(before, map-marker, 28px);
      padding-left: $grid-gutter-width;

      &:before {
        position: absolute;
        left: $grid-gutter-width / 2;
      }
    }

    &:after {
      font-size: 28px;
    }
  }

  button {
    @include media-breakpoint-up(md) {
      margin-left: $grid-gutter-width / 2;
    }
  }
}
