.quickselect {
  display: flex;
  padding: $grid-gutter-width / 2;
  background: __get($theme-colors, 'primary60');
  margin-bottom: $grid-gutter-width;
  flex-direction: column;
  position: relative;

  label {
    position: absolute;
    left: $grid-gutter-width / 2;
    font-size: 9px;
    transform: translateY($grid-gutter-width);
    opacity: 0;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
  }

  .select-holder {
    height: 50px;
    margin-bottom: $grid-gutter-width / 2;

    &.selected {
      select {
        padding-top: $grid-gutter-width / 2;
      }

      label {
        transform: translateY(5px);
        opacity: 1;
      }
    }

    select {
      width: 100%;
      padding-top: 0;
      padding-right: $grid-gutter-width * 1.5;
      transition: all 0.2s ease-in-out;
      font-size: 14px;

      &.small {
        @include media-breakpoint-only(xl) {
          font-size: 12px;
        }
      }
    }

    &.disabled:after {
      opacity: 0.2;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      left: auto;
      width: auto;
      right: 0;
      top: 500px;
      bottom: auto;
    }

    @include media-breakpoint-up(lg) {
      top: 430px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      @include media-breakpoint-up(md) {
        flex-direction: column;
      }

      li {
        flex: 1 0 50%;
        display: flex;

        @include media-breakpoint-up(md) {
          display: list-item;
          flex: 0 auto;
          margin-bottom: $grid-gutter-width / 4;
          text-align: right;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .btn {
      height: auto;
      @include getFont();
      border-radius: 0;
      flex: 1 auto;
      display: flex;
      font-size: 14px;
      line-height: 1.3;
      padding: 0 $grid-gutter-width / 2;
      min-height: 50px;
      justify-content: center;

      @include media-breakpoint-up(md) {
        padding: 0 $grid-gutter-width;
        line-height: 50px;
        font-size: 16px;
        display: inline-block;
        transform: translateX(10px);
        transition: transform 0.2s ease-in-out;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:hover {
          transform: translateX(0);
        }
      }
    }
  }
}
