a {
  footer & {
    @include getFont(b);
    color: _get($theme-colors, 'white');

    &:hover {
      color: _get($theme-colors, 'secondary');
      text-decoration: none;
    }
  }

  main & {
    @include getFont(b);
    color: _get($theme-colors, 'info');

    &:hover {
      color: _get($theme-colors, 'info');
      text-decoration: none;
    }
  }

  &.icon {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .color-inherit & {
    color: inherit;
  }

  &[title='Facebook'],
  &[title='facebook'] {
    @include icon(before, 'facebook');

    &:before {
      top: 7px;
      left: 12px;
    }
  }

  &[title='Twitter'],
  &[title='twitter'] {
    @include icon(before, 'tweet');

    &:before {
      top: 5px;
      left: 7px;
    }
  }

  &[title='Instagram'],
  &[title='instagram'] {
    @include icon(before, 'instagram');

    &:before {
      top: 6px;
      left: 6px;
    }
  }

  &[title='TikTok'],
  &[title='tiktok'] {
    @include icon(before, 'tiktok');

    &:before {
      top: 6px;
      left: 6px;
    }
  }

  &[title='LinkedIn'],
  &[title='linkedin'] {
    @include icon(before, 'linkedin');

    &:before {
      top: 6px;
      left: 6px;
    }
  }

  &[title='YouTube'],
  &[title='youtube'] {
    @include icon(before, 'youtube');

    &:before {
      top: 6px;
      left: 6px;
    }
  }

  &[title='Facebook'],
  &[title='Twitter'],
  &[title='Instagram'],
  &[title='TikTok'],
  &[title='LinkedIn'],
  &[title='YouTube'],
  &[title='facebook'],
  &[title='twitter'],
  &[title='instagram'],
  &[title='tiktok'],
  &[title='linkedin'],
  &[title='youtube'] {
    width: 45px;
    height: 45px;
    position: relative;
    background: _get($theme-colors, 'info');
    margin-right: $grid-gutter-width / 4;
    overflow: hidden;
    color: _get($theme-colors, 'info') !important;
    display: inline-block;

    &:before {
      color: _get($theme-colors, 'white');
    }

    &.small-social-media {
      width: 25px;
      height: 25px;
      top: 6px;
      margin-right: 0;

      &:before {
        top: 5px;
        left: 8px;
        font-size: 16px;
      }
    }
  }

  &[data-cart='0'] {
    &:after {
      opacity: 0;
    }
  }

  &[data-cart] {
    &:after {
      @include getFont();
      font-size: 10px;
      content: attr(data-cart);
      position: absolute;
      top: 9px;
      right: 0;
      width: 15px;
      height: 15px;
      display: block !important;
      min-width: auto;
      border-radius: 100%;
      text-align: center;
      line-height: 15px;
      background: rgba(_get($theme-colors, 'info'), 0.8);
      color: #fff;
    }
  }

  &.btn {
    height: $button-height;
    display: flex;

    &.inline {
      display: inline-flex;
    }

    align-items: center;

    .overlay & {
      display: inline;
    }
  }
}
