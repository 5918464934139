.frame-type-theme_textimage {
  display: flex;
  flex-direction: column;
  margin-bottom: $grid-gutter-width;

  .text {
  }

  &.frame {
    &-layout {
      &-0 {
        header {
          order: 0;
        }
      }

      &-110,
      &-111 {
        .text {
          order: 2;

          & > p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &-110 {
        & > header {
          order: 0;
        }

        & > picture {
          order: 1;
        }
      }

      &-111 {
        & > header {
          order: 1;
        }

        & > picture {
          order: 0;
        }
      }
    }
  }
}
