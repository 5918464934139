.calendar {
  margin: $grid-gutter-width ($grid-gutter-width / -4);
  color: _get($theme-colors, 'primary61');
  display: flex;
  flex-wrap: wrap;

  &-item {
    padding: 0;

    @include media-breakpoint-up(md) {
      margin-left: $grid-gutter-width / 4;
      margin-right: $grid-gutter-width / 4;
      margin-bottom: $grid-gutter-width / 2;
    }

    display: flex;

    &.break {
      display: flex;
      align-items: center;
      flex-basis: 100%;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
      }

      h5 {
        margin-bottom: 0;
      }
    }

    &:before {
      display: none;
    }

    &__date {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $grid-gutter-width / 4;
      @include border-radius;
      border: 1px solid _get($theme-colors, 'primary40');
      line-height: 1;
      width: 103px;
      flex: 0 0 103px;

      & + & {
        @include icon(before, arrow-right-thick);
        position: relative;
        margin-left: $grid-gutter-width / 4;

        &:before {
          position: absolute;
          left: -15px;
          font-size: 23px;
          color: rgba(119, 159, 181, 0.6);
          top: 49px;

          @include media-breakpoint-up(sm) {
            left: -18px;
            top: 42px;
            font-size: 32px;
          }

          @include media-breakpoint-up(md) {
            top: 49px;
          }
        }
      }

      &-year {
        @include px-to-rem(font-size, 14px);
        color: _get($theme-colors, 'primary40');
        border-bottom: 1px solid _get($theme-colors, 'primary40');
        width: 100%;
        text-align: center;
        padding-bottom: $grid-gutter-width / 4;
      }

      &-dayofweek {
        @include getFont('b');
        @include px-to-rem(font-size, 14px);
        margin: ($grid-gutter-width / 4) 0 3px 0;
      }

      &-date {
        @include getFont('b');
        @include px-to-rem(font-size, 23px);
        margin-bottom: $grid-gutter-width / 4;
      }

      &-time {
        @include px-to-rem(font-size, 14px);
        color: _get($theme-colors, 'primary40');
      }
    }

    &__description {
      margin-top: $grid-gutter-width / 2;

      @include media-breakpoint-up(md) {
        margin-left: $grid-gutter-width / 2;
        margin-top: 0;

        position: relative;
        padding-left: $grid-gutter-width;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -(134px / 2);
          left: -5px;
          height: 134px;
          width: 30px;
          background: url(#{$theme-resources}/Images/Svg/curly-bracket.svg) no-repeat center;
          background-size: contain;
        }
      }

      line-height: 1.2;
      display: inline-block;

      time {
        display: block;
        line-height: 1.2;
        @include getFont(b);
      }

      &-break {
        flex-basis: 100%;
      }
    }
  }
}

.calendar-list {
  li {
    padding: 0;
    margin: 0 0 $grid-gutter-width / 4 0;

    &:before {
      display: none;
    }

    span {
      display: inline-block;
    }

    & > span {
      display: block;

      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }
  }

  &__item {
    color: _get($theme-colors, 'primary');

    &-weeknumber {
      color: _get($theme-colors, 'primary40');
      @include px-to-rem(font-size, 12px);
    }

    &-start {
    }

    &-end {
      position: relative;
      @include icon(before, arrow-right-thick);

      &:before {
        position: absolute;
        left: -15px;
        top: 3px;
        font-size: 14px;

        @include media-breakpoint-up(md) {
          left: -27px;
          top: 1px;
          font-size: 21px;
        }
      }
    }

    &-spacer {
      width: 150px;
    }

    &-title {
      margin-top: $grid-gutter-width;
      margin-bottom: $grid-gutter-width / 2;
    }

    &-day {
      display: inline-block;
      width: $grid-gutter-width;
    }

    &-date {
      @include getFont('b');
      width: 120px;
    }

    &-time {
      margin-left: $grid-gutter-width;
      color: _get($theme-colors, 'primary40');
      width: 120px;

      span + span {
        &:before {
          content: '-';
        }
      }
    }

    &-description {
      margin-left: $grid-gutter-width;
    }
  }
}
