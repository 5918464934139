address {
  aside & {
    margin: $grid-gutter-width / 2 0;
  }

  p {
    margin: 0;
  }

  b,
  strong {
    @include getFont();
  }

  a {
    line-height: 1.5;

    & + span {
      display: block;
      margin-top: $grid-gutter-width / 2;
    }
  }
}
