.grid-wrapper {
  display: grid;
  aspect-ratio: 16/9;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  border: 1px solid rgba(map-get($theme-colors, 'primary'), 0.9);

  @include media-breakpoint-up(sm) {
    border: none;
  }

  .grid {
    &-layer-0,
    &-layer-1 {
      grid-row: 1;
      grid-column: 1;
    }

    &-layer-1 {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      width: 75%;
      height: auto;
      align-self: center;
      margin: 0;
      justify-self: center;

      p:last-child {
        margin-bottom: 0;
      }

      grid-row: 2;

      @include media-breakpoint-up(sm) {
        grid-row: 1;
      }
    }
  }
}
