.icon {
  @extend .d-flex;
  position: relative;
  @include icon(before);

  header &,
  &.icon-map-marker {
    span {
      @extend .sr-only;
    }
  }

  @each $icon in map-keys($icons) {
    &.icon-#{$icon} {
      &:before {
        content: _get($icons, $icon);
        font-size: 18px;
        margin-right: $grid-gutter-width / 4;
      }
    }
  }

  &.icon-blank:before {
    content: '';
    width: 20px;
    margin-right: $grid-gutter-width / 4;
  }

  // icon overrides are sometimes
  // needed for better positions and stylings
  &.icon-directions-walk:before {
    font-size: 16px;
  }

  &.icon-paperplane:before {
    font-size: 14px;
  }

  &.icon-gdpr:before {
    margin-top: -2px;
  }

  &.icon-user-group:before {
    font-size: 14px;
  }

  &.icon-brochure:before {
    font-size: 15px;
  }

  &[data-type='jpg'],
  &[data-type='png'],
  &[data-type='gif'],
  &[data-type='bmp'],
  &[data-type='psd'],
  &[data-type='ai'],
  &[data-type='svg'] {
    @include icon(before, file-image-o);
    &:before {
      color: _get($theme-colors, 'info');
      margin-right: $grid-gutter-width / 2;
    }
    align-items: center;
  }

  &[data-type='pdf'] {
    @include icon(before, file-pdf-o);
    &:before {
      color: _get($theme-colors, 'info');
      margin-right: $grid-gutter-width / 2;
    }
    align-items: center;
  }

  &[data-type*='doc'],
  &[data-type*='xls'],
  &[data-type='txt'] {
    @include icon(before, file-text-o);
    &:before {
      color: _get($theme-colors, 'info');
      margin-right: $grid-gutter-width / 2;
    }
    align-items: center;
  }

  @each $color in map-keys($theme-colors) {
    &.c-#{$color} {
      color: _get($theme-colors, $color);

      &:before {
        opacity: 1;
      }
    }
  }

  .alert &:before {
    margin: 0;
    align-self: flex-start;
  }
}
