.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $grid-gutter-width;
  background: rgba(#fff, 0.7);

  div {
    &.map {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-attachment: fixed;
      z-index: 0;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      align-self: unset;
      max-width: none;
      filter: blur(10px);
      box-shadow: inset 0 0 150px rgba(#000, 0.3);
      z-index: 0;

      &--bg {
        background: url(#{$theme-resources}/Images/MapBg.webp) no-repeat center;
      }
    }

    border-radius: $border-radius;
    background: _get($theme-colors, 'lighter');
    padding: $grid-gutter-width;
    max-width: 80%;
    align-self: center;
    z-index: 1;
    @include boxshadow();

    p {
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.tmp {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  &-box {
    width: 90%;
    position: relative;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    border: 10px solid __get($theme-colors, secondary);
    background: __get($theme-colors, 'white');
    padding: 70px $grid-gutter-width $grid-gutter-width $grid-gutter-width;

    a {
      width: max-content;
    }

    span.btn {
      position: absolute;
      top: -24px;
      right: -24px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #fff;
      border: 4px solid #004985;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        width: 20px;
        height: 2px;
        background: #004985;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 50%;
        margin-left: -10px;
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 50px;
    background: url(../Images/Svg/ifs-studentenkurse.svg) no-repeat __get($theme-colors, secondary) center top;
    background-size: 170px auto;
    border-bottom-right-radius: 5px;

    &[data-root='429'] {
      background-image: url(../Images/Svg/logo_matura.svg);
    }

    &[data-root='430'] {
      background-image: url(../Images/Svg/logo_nachhilfe.svg);
    }

    &[data-root='431'] {
      background-image: url(../Images/Svg/logo_bildungsforum.svg);
    }

    &[data-root='2806'] {
      // @TODO: implement PA logo
      background: red;
      //background-image: url(../Images/Svg/logo_bildungsforum.svg);
    }
  }
}
