/** base layout definitions **/
html,
body {
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
}
