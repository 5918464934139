.badges {
  @extend .d-flex;

  @each $badge, $color in $badges {
    .#{$badge} & {
      color: _get($theme-colors, $color);

      &.invert {
        color: _get($theme-colors, 'dark');
      }
    }
  }
}
