.slider {
  * {
    font-family: $teaser-font;
  }

  #header & {
    background: #efefef;

    picture {
      // disable general mb for this component
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: auto;
    }

    figure {
      position: relative;

      figcaption {
        height: auto;
        position: absolute;
        bottom: $grid-gutter-width;
        right: 190px;
        width: auto;
        padding: 0;

        div {
          padding: 0;
          height: auto;

          span {
            @extend .h2;
            @include getFont(regular, teaser);
            @include px-to-rem(line-height, 42px);
            height: auto;
            color: _get($theme-colors, 'dark');
            background: _get($theme-colors, 'white');
            padding: 5px 10px;
            @include border-radius();
            border-bottom-right-radius: unset;
          }

          & + div {
            padding-left: $grid-gutter-width;
            margin-top: -5px;

            span {
              border-top-left-radius: unset;
              @include border-bottom-radius($border-radius);
            }
          }
        }
      }
    }
  }
}
